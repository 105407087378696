<template>
	<div class="page-container">
		<nav-bar title="教练员在线报名" :fixed="true" id="navbar"></nav-bar>
		<!-- 报名表单 -->
		<div class="form-area" v-if="!isSignupSuccess && !isSignupConfirm">
			<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
				山东教练员注册入口!
			</van-notice-bar>
			<van-form @submit="onConfirm" ref="formData" action="">
				<van-field
					v-model="form.sfzjh"
					name="sfzjh"
					label="身份证号"
					placeholder="身份证号码"
					maxlength="18"
					:rules="[{pattern:/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,message:'请填写正确的身份证号码'}]"
				/>

				<van-field
					v-model="form.xm"
					name="xm"
					label="真实姓名"
					placeholder="真实姓名"
					:rules="[{required:true, message:'请填写真实姓名'}]"
				/>
				<van-field
					v-model="form.sjhm"
					name="sjhm"
					type="tel"
					label="手机号码"
					placeholder="手机号码"
					maxlength="11"
					:rules="[{pattern:/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/,message:'请填写正确的手机号码'}]"
				/>
				<div class="title">
					<span style="color: #409EFF;">请如实填写以下报名信息</span>
				</div>
				<van-field
					v-model="form.corpName"
					name="corpName"
					label="所属驾校"
					placeholder="请选择所属驾校"
					readonly
					@click="openIndexBar"
				/>
				<!-- 可选课程 -->
				<van-field
					readonly
					clickable
					name="pxcx"
					v-model="form.pxcx"
					label="可选课程"
					placeholder="点击选择可选课程"
					@click="showPicker = true"
					:rules="[{ required: true, message: '请选择学习课程' }]"
				/>
				<van-popup v-model="showPicker" position="bottom">
					<van-picker
						show-toolbar
						:columns="columns"
						@confirm="onConfirmPicker"
						@cancel="showPicker = false"
						value-key="text"
					/>
				</van-popup>
				<van-field
					v-if="this.form.pxcx_value == '2'"
					v-model="form.ccfzsj"
					name="ccfzsj"
					label="初次发证"
					placeholder="初次发证时间(如:20110621)"
					:rules="[{ pattern: /^(?:(?!0000)[0-9]{4}(?:(?:0[13578]|1[02])(?:0[1-9]|[12][0-9]|3[01])|(?:0[469]|11)(?:0[1-9]|[12][0-9]|30)|02(?:0[1-9]|1[0-9]|2[0-8]))|(?:(((\d{2})(0[48]|[2468][048]|[13579][26])|(([02468][048])|([13579][26]))00))0229))$/, 
					message:'请填写正确的日期(如:20110621)'}]"
				/>
				<van-field 
					readonly
					clickable
					name="radio" 
					label="学习平台" 
					v-model="form.studyFirm" 
					:rules="[{required:true,message:'请选择一个学习平台'}]">
					<template #input >
						<van-radio-group v-model="form.studyFirm" direction="horizontal" >
						<van-radio name="北京训美"><img style="width:50px " src="../../assets/shandong/xunmeizaixian.png" /><br/>北京训美</van-radio>
						<van-radio name="北京通安"><img style="width:50px " src="../../assets/shandong/tongan.jpg" /><br/>北京通安</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<div style="margin: 16px">
					<van-button round block type="info" native-type="submit">下一步</van-button>
				</div>
			</van-form>
			<!-- 驾校索引页 -->
			<van-popup
				v-model="showIndexBar"
				position="left"
				:style="{ width: '100%', height:'100%' }">
				<form action="javascript: return true;" style="padding: 0; margin: 0;">
					<van-search
						v-model="searchCorpValue"
						show-action
						placeholder="请输入搜索驾校关键词"
						@search="onCorpSearch"
						@cancel="onCorpSearchCancel"
						@clear="onCorpSearchClear"
					/>
				</form>
				<van-index-bar ref="indexBar">
					<template v-for="(item, index) in enterpriseIndexData">
						<van-index-anchor :index="index" :key="index">{{ index }}</van-index-anchor>
						<van-cell
							style="margin-top:0; box-shadow:0 0 0 0; border-radius:0;"
							:title="item1.corpName"
							@click="selectCorp(item1.indexChar, item1.corpId, item1.corpName)"
							v-for="(item1, index1) in item" :key="index1">
						</van-cell>
					</template>
				</van-index-bar>
			</van-popup>
		</div>

		<!-- 报名信息确认 -->
		<div class="confirm-area" v-if="isSignupConfirm">
			<div class="checked-plan">
				<van-cell-group title="请确认您的报名信息" inset>
					<van-cell title="真实姓名" :value="form.xm"/>
					<van-cell title="身份证号" :value="form.sfzjh"/>
					<van-cell title="手机号码" :value="form.sjhm"/>
					<van-cell title="所属驾校" :value="form.corpName"/>
					<van-cell v-if="form.pxcx_value == '2'" title="初次发证" :value="form.ccfzsj"/>
					<van-cell title="报名课程" :value="form.pxcx"/>
					<van-cell title="学习平台" :value="form.studyFirm"/>
					<div style="margin: 16px; text-align: center">
						<van-button round plain type="info" native-type="button" @click="onModify"> 修改</van-button>&nbsp;
						<van-button round type="info" native-type="button" @click="onSubmit"> 确认提交</van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- 报名成功 -->
		<div class="checked-area" v-if="isSignupSuccess">
			<van-icon name="checked" color="#67C23A" size="80"/>
			<div><span class="checked-msg">恭喜您，报名成功,资料审核后方可学习,请耐心等待。</span></div>
			<div class="checked-plan">
				<van-cell-group title="报名信息" inset>
					<van-cell title="真实姓名" :value="form.xm"/>
					<van-cell title="身份证号" :value="form.sfzjh"/>
					<van-cell title="手机号码" :value="form.sjhm"/>
					<van-cell title="所属驾校" :value="form.corpName"/>
					<van-cell v-if="form.pxcx_value == '2'" title="初次发证" :value="form.ccfzsj"/>
					<van-cell title="报名课程" :value="form.pxcx"/>
					<van-cell title="学习平台" :value="form.studyFirm"/>
				</van-cell-group>
			</div>
		</div>

		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#4994df">{{ title || '提交中···' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {NavBar} from "vant";
	import {signupCoach,getEnterpriseIndexList} from "../../api/user";
	import Vue from 'vue';
	import { RadioGroup, Radio } from 'vant';

	Vue.use(RadioGroup);
	Vue.use(Radio);

	export default {
		name: "signup-liaoning-push",
		components: {NavBar},
		data() {
			return {
				ccfzsjpattern: /^(?:(?!0000)[0-9]{4}(?:(?:0[13578]|1[02])(?:0[1-9]|[12][0-9]|3[01])|(?:0[469]|11)(?:0[1-9]|[12][0-9]|30)|02(?:0[1-9]|1[0-9]|2[0-8]))|(?:(((\d{2})(0[48]|[2468][048]|[13579][26])|(([02468][048])|([13579][26]))00))0229))$/,
				title: '',
				showLoading: false,
				isSignupSuccess: false,
				isSignupConfirm: false,
				searchResult: false,
				showPicker: false,
				showPickerNd: false,
				showPickerDq: false,
				showPickerSy: false,
				showIndexBar: false,
				searchCorpValue: '',
				agentId:'66',
				cityCode:'370100',
				indexChar: '',
				isSearched: false,
				corpName: '',
				trainYear: '',
				enterpriseIndexData: [],
				form: {
					cityCode:'370100',
					xm:'',
					sfzjh:'',
					sjhm: '',
					ccfzsj: '',
					pxcx:'',
					studyFirm:'',
					corpName:'',
					enterpriseId: 0,
				},
				columns: [
					{text: '教练员岗前教育', value: '1'},
					{text: '教练员继续教育', value: '2'},
				],
			}
		},
		methods: {
			selectCorp(index, corpId, corpName) {
				this.indexChar = index;
				this.form.enterpriseId = corpId;
				this.form.corpName = corpName;
				this.showIndexBar = false;
			},
			onCorpSearch(val) {
				this.searchEnterpriseList(this.form.cityCode, this.agentId, val);
				this.isSearched = true;
			},
			onCorpSearchCancel() {
				if(this.isSearched){
					this.searchEnterpriseList(this.form.cityCode, this.agentId, '');
					this.isSearched = false;
				}
				this.showIndexBar = false;
			},
			onCorpSearchClear() {
				if(this.isSearched){
					this.searchEnterpriseList(this.form.cityCode, this.agentId, '');
					this.isSearched = false;
				}
			},
			onConfirmPicker(value) {
				this.form.pxcx = value.text;
				this.form.pxcx_value = value.value;
				this.showPicker = false;
			},
			onConfirm() {
				console.log("报名信息",this.form)
				this.isSignupConfirm = true;
			},
			onModify() {
				this.isSignupConfirm = false;
			},
			searchEnterpriseList(cityCode, agentId, keyword) {
				getEnterpriseIndexList({
					cityCode: cityCode,
					agent: agentId,
					enterpriseName: keyword
				}).then((res) => {
					this.enterpriseIndexData = res;
				}).catch(() => {});
			},
			openIndexBar() {
				this.searchEnterpriseList(this.form.cityCode, this.agentId, '');
				this.showIndexBar = true;
				if(this.indexChar !== '') {
					this.$nextTick(()=>{
						this.$refs.indexBar.scrollTo(this.indexChar);
					})
				}
			},
			onSubmit() {
				this.showLoading = true;
				signupCoach({
					certNum: this.form.sfzjh,
					studentName: this.form.xm,
					mobile: this.form.sjhm,
					studyCourse: this.form.pxcx,
					certificateDate: this.form.ccfzsj,
					studyFirm: this.form.studyFirm,
					studentState: 1,
					enterpriseName: this.form.corpName,
					enterpriseId: this.form.enterpriseId,
				})
				.then(() => {
					this.isSignupSuccess = true;
					this.isSignupConfirm = false;
					this.showLoading = false;
				})
				.catch(() => {
					this.showLoading = false;
				});
			},
		}
	}
</script>

<style scoped>
	#navbar {
		background-color: #409eff;
	}

	#navbar /deep/ .van-ellipsis {
		color: #fff !important;
	}

	#navbar /deep/ .van-nav-bar__text {
		color: #fff !important;
	}

	.form-area {
		margin-top: 46px;
	}

	.title {
		margin: 0;
		padding: 16px 16px 16px;
		color: rgba(69, 90, 100, 0.6);
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
	}

	.checked-area {
		margin-top: 90px;
		text-align: center;
	}

	.confirm-area {
		text-align: center;
	}

	.checked-msg {
		font-size: 14px;
		color: #67c23a;
	}

	.checked-plan {
		text-align: left;
		margin-top: 40px;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.block {
		width: 120px;
		height: 120px;
		border-radius: 15px;
		background-color: #fff;
		padding-top: 32px;
	}
</style>
