<template>
	<div class="page-container">
		<div class="video-wrap">
			<div class="head">
				<page-header
					:transparent="true"
					:bgColor="'transparent'"
					@navBack="back"
					:pageTitle="''"
				>
					<!-- <template v-slot:default>
					  <div @click="showVideoType" class="video-type">标清</div>
					</template> -->
				</page-header>
			</div>
			<!-- <video id="video" preload="metadata" src="http://wxsnsdy.tc.qq.com/105/20210/snsdyvideodownload?filekey=30280201010421301f0201690402534804102ca905ce620b1241b726bc41dcff44e00204012882540400&bizid=1023&hy=SH&fileparam=302c020101042530230204136ffd93020457e3c4ff02024ef202031e8d7f02030f42400204045a320a0201000400"> -->
			<!-- <video id="video" ref="videoRef" preload="metadata" src="https://www.runoob.com/try/demo_source/mov_bbb.mp4">
			  您的浏览器不支持video标签，请更换浏览器重试。
			</video> -->
			<!-- <video ref="videoPlayer" class="video-js"></video> -->
			<!--评价模块-->
			<c-evaluate :show="false" title="课程评价"></c-evaluate>
			<div class="video-js" style="width:100%;height:210px;background:#000" id="reviewer"></div>

			<!-- <div class="video-tool">
			  <div class="left-tool" @click="playOrPause">
				<van-icon v-show="!isPlay" size="22" name="play-circle-o" />
				<van-icon v-show="isPlay" size="22" name="pause-circle-o" />
			  </div>
			  <div class="center">
				<span class="time start-time">00:00</span>
				<div class="line">
				  <div class="inner-line"></div>
				</div>
				<span class="time end-time">12:00</span>
			  </div>
			  <div class="right-tool">
				<van-icon size="22" name="expand-o" />
			  </div>
			</div> -->

			<div class="video-mask" v-if="showMask1||showMask2||showMask3">
				<div v-if="showMask1" class="exam-wrap">
					<p class="c-white">Loading...</p>
					<div class="btn-wrap">
						<span class="btn">右转</span>
						<span class="btn">左转</span>
						<span class="btn">直行</span>
					</div>
				</div>
				<div v-if="showMask2" class="exam-wrap tips">
					<p class="c-main text-center">视频已观看完毕~</p>
					<div class="btn-wrap">
						<span @click="replay()" class="btn" style="display:flex;align-items:center;"><van-icon size="20" name="replay" style="margin-right:4px"/>
							<span>重新观看</span>
						</span>
						<span @click="playNext()" v-if="!nextWatchVideo.done" class="btn">下一个</span>
					</div>
				</div>
				<div v-if="showMask3" class="exam-wrap tips">
					<p class="c-main text-center">已为您恢复至上次学习节点</p>
					<div class="btn-wrap">
						<span @click="replay()" class="btn" style="display:flex;align-items:center;"><van-icon size="20" name="replay" style="margin-right:4px"/>
							<span>重新观看</span>
						</span>
						<span @click="playNext()" v-if="!nextWatchVideo.done" class="btn">继续学习</span>
					</div>
				</div>
				<!-- <div v-if="showMask3" class="exam-wrap video-type">
				  <div class="btn-wrap">
					<span @click="hideVideoType(item.value)" class="btn" v-for="(item,index) in videoTypes" :key="index">{{item.label}}</span>
				  </div>
				</div> -->
			</div>
		</div>

		<div class="video-content" :style="calcHeight()">
			<div class="list">
				<span class="title s-14 line">{{ reviewName }}</span>
				<div v-if="studyDone==0" class="right-side">
					<!--<van-button @click="signInOrOut" class="btn" :loading="loading" type="primary">{{signId?'签退':'签到'}}</van-button>-->
					<van-button @click="clickAppPlay" class="btn" :loading="loading" type="primary">
						{{ signId ? '签退' : '签到' }}
					</van-button>
					<input v-if="!isUseJSPlayPhoto" ref="imageInput" class="img-input" @change="imgChange($event)" type="file" accept="image/*" capture="user">
				</div>
			</div>
			<div class="video-chapter">
				<div class="left">
					<div class="chapter-list">
						<div v-for="(item,index) in videoList" :key="index" @click="changeChapter(index)" :class="['chapter-item', {'active': index==activeChapter}]">
							<img
								v-if="item.unlock||index==0||index<=activeChapter||(canPractice&&index==activeChapter+1)||nextWatchVideo.done"
								:src="require('../../assets/icon/icon-unlock'+(appno==1?'':appno)+'.png')" alt="icon">
							<!-- <img v-if="!item.unlock&&index!=0&&!(canPractice&&index==activeChapter+1)&&!nextWatchVideo.done" src="@/assets/icon/icon-lock.png" alt="icon"> -->
							<img v-else src="@/assets/icon/icon-lock.png" alt="icon">
							<span>{{ item.chapterName }}</span>
						</div>
						<!-- <div class="chapter-item">
						  <img src="@/assets/icon/icon-lock.png" alt="icon"><span>第二章</span>
						</div> -->
					</div>
				</div>
				<div class="right">
					<div class="section-wrap" v-if="videoList[activeChapter]">
						<div class="chapter-title">{{ videoList[activeChapter].chapterName }}</div>
						<template v-for="(item,index) in videoList[activeChapter].children">
							<!-- 节 -->
							<template v-if="item.type==2">
								<div class="section" :key="index+'-'">
									<span class="title s-14 line">{{ item.chapterName }}</span>
									<template v-if="item.children">
										<div class="section-list" :style="{maxHeight:item.auto?'initial':'200px'}">
											<div
												:key="index+'-'+i"
												v-for="(video,i) in item.children"
												@click="playVideo(video,index,i)"
												class="section-item"
												:class="{
													no_study: video.watchedFlag == 0 && playedVideo.video.videoCcid !== video.video.videoCcid
												}"
											>
												<div
													:style="`background-image: url(`+backGroundUrl+`)`"
													class="top">
													<span v-if="video.video" :class="[
														'status', {
															'unread':video.watchedFlag==0 && playedVideo.video.videoCcid !== video.video.videoCcid
														}
													]">{{ playedVideo.video.videoCcid === video.video.videoCcid ? '学习中' : video.watchedFlag == 0 ? '未学' : '已学' }}</span>
													<span v-if="video.video" class="video-name">{{ video.video.videoName }}</span>
													<span v-if="video.video" class="duration">{{ video.video.videoDuration | transToMin }}</span>
												</div>
												<span>{{ video.chapterName }}</span>
											</div>
											<div @click="seeAll(index)" class="section-button" >
												<a>{{ item.auto ? '收起全部' : '查看全部' }}</a>
											</div>
										</div>
									</template>
								</div>
							</template>
							<!-- 练习 -->
							<template v-else-if="item.type==4 && appno!=3">
								<div :key="index+'-'" :class="['section read-status', { transparent: !canPractice }]">
									<span>恭喜完成本章学习，去练练吧</span>
									<span @click="navToPractice(item.basePkId)" class="btn">随堂练习</span>
								</div>
							</template>
							<template v-else-if="item.type==4 && appno==3">
								<div :key="index+'-'" :class="['section read-status', { transparent: !canPractice }]">
									<span>恭喜完成本月学习，请进行考核</span>
									<span @click="navToPractice(item.basePkId)" class="btn">考核</span>
								</div>
							</template>
						</template>
					</div>

					<!-- <div v-if="activeChapter==videoList.length-1 && mockTestFlag === '1'" :class="['section read-status', { transparent: !canPractice }]"> -->
					<div v-if="videoList[videoList.length-1] !== undefined && videoList[videoList.length-1].watchedFlag === '1' && mockTestFlag === '1'" class="section read-status">
					<!-- <div v-if="videoList[videoList.length-1] !== undefined && videoList[videoList.length-1].watchedFlag === '1' && mockTestFlag === '1'" :class="['section read-status', { transparent: !canPractice }]"> -->
						<span>完成全部课程学习，检验下学习效果吧</span>
						<span @click="navToTest()" class="btn" style="font-size: 18px; color: #ee0a24;"> {{belongOrganization === '13'?'测试':'考试' }}</span>
					</div>
				</div>
			</div>
		</div>

		<van-popup round v-model="showPop">
			<div class="popup-content">
				<div class="content">
					如您退出学习，请先点击签退按钮签退，否则会影响学时送审。
				</div>
				<div class="btn-wrap">
					<van-button @click="confirm" class="btn default" type="primary">退出</van-button>
					<div v-if="studyDone==0" class="right-side">
						<!--<van-button @click="signInOrOut" class="btn" type="primary">签退</van-button>-->
						<van-button @click="clickAppPlay" class="btn" type="primary">签退</van-button>
						<input v-if="!isUseJSPlayPhoto" ref="imageInput2" class="img-input" @change="imgChange($event)" type="file" accept="image/*" capture="user">
					</div>
				</div>
			</div>
		</van-popup>
		<van-popup round v-model="showPop1">
			<div class="popup-content">
				<div class="content">
					如您退出学习，请先点击签退按钮签退，否则会影响学时送审。
				</div>
				<div class="btn-wrap">
					<van-button @click="cancle" class="btn default" type="primary">取消</van-button>
					<van-button @click="cancle" class="btn" type="primary">确定</van-button>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import PageHeader from "../../components/page-header.vue";
	// import videojs from "video.js";
	// import "video.js/dist/video-js.css";
	import "./video.css";
	import {viewLogPush, getVideoList, signIn, signOut, watchVideo, compareFace, wxCompareFace,getPlanInfo} from '../../api/user';
	import Compressor from 'compressorjs';
	// import { transToMin } from '../../utils/index';
	export default {
		name: 'videocomp',
		data() {
			return {
				backGroundUrl:'https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/d3f5ff377f40d1efde1c67769bf6874.jpg',
				middleFace: false, // 中途是否出现人脸
				submitStudyTimer: null, // 定时器存放地址
				loading: false,
				showMask1: false,
				showMask2: false, // 已看完
				showMask3: false, // 播放记录
				subject: 1, // 科目
				courseId: '', // 科目的课程id
				signId: '', // 签到记录
				playedVideo: {}, // 当前观看视频
				nextWatchVideo: {}, // 记录上次观看的下一个
				nextActiveChapter: 0,
				nextActiveSection: 0,
				nextActiveVideo: 0,
				watchLength: 0,
				videoLength: 0,
				videoTypes: [
					{label: '超清', value: 1,},
					{label: '标清', value: 2,},
					{label: '流畅', value: 3,},
				],
				videoList: [],
				activeChapter: 0, // 当前章
				activeSection: 0, // 当前节
				activeVideo: 0, // 当前视频
				showPop: false,
				showPop1: false, // 练习考试前
				player: null,
				isPlay: false,
				playerOptions: {
					controls: true,
					// playbackRates: [], //可选择的播放速度
					autoplay: false, //如果true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 视频一结束就重新开始。
					preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: "zh-CN",
					aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [
						{
							type: "",
							src: "https://www.runoob.com/try/demo_source/mov_bbb.mp4", //url地址
						},
					],
					poster: "", //你的封面地址
					// width: document.documentElement.clientWidth,
					notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						currentTimeDisplay: true,
						volumePanel: false,
						timeDivider: true, //当前时间和持续时间的分隔符
						durationDisplay: true, //显示持续时间
						remainingTimeDisplay: false, //是否显示剩余时间功能
						fullscreenToggle: true, //全屏按钮
					},
				},

				canPractice: false,
				studyDone: 0,
				avatar: '',

				// ccplayer
				reviewCCID: '',
				reviewName: '',
				reviewer: null,
				mockTestFlag: '0', //考试状态
				playModeType: '0', //连续播放状态
				belongOrganization: '',
			};
		},
		components: {
			PageHeader,
		},
		computed: {
			isUseJSPlayPhoto() {
				if (this.$store.state.cache.screen.wx) return true
				if (this.$route.query.state === 'js') return false
				// if (sessionStorage.getItem('anhuihefei') === '1') {
				//	return false
				// }
				if (this.$store.state.cache.single && this.$store.state.cache.single.state === 'single') return true
				return true
			},
			navHeight() {
				return Number(sessionStorage.getItem("navHeight") || 0);
			},
			appno() {
				return this.$store.state.app.appno
			},
			userid() {
				return this.$store.state.user.userid
			},
			courseid() {
				return this.$store.state.user.courseid
			},
			planid() {
				return this.$store.state.user.planid
			},
			// canPractice: {
			//   get () {
			//     if(this.videoList[this.activeChapter].children&&this.videoList[this.activeChapter].children[this.activeSection].children) {
			//       // 有章 有节 有视频
			//       // return (this.activeVideo >= this.videoList[this.activeChapter].children[this.activeSection].children.length-1)&&this.videoList[this.activeChapter].children[this.activeSection].children[this.activeVideo].watchedFlag==1
			//       return this.videoList[this.activeChapter].children[this.activeSection].children[this.activeVideo].watchedFlag==1;
			//     } else {
			//       return true;
			//     }
			//   },
			//   set (val) {
			//     console.log(val)
			//   }
			// },
			practiceVideo() {
				if (this.videoList[this.activeChapter] && this.videoList[this.activeChapter].children) {
					return this.videoList[this.activeChapter].children.find(v => v.type == 4)
				} else {
					return ''
				}
			},
		},
		created() {
			this.setPlayerCallBack();
			this.getPlanInfo();
			this.chageBackGround();
			// 清楚签到人脸
			this.$store.commit('cache/set_face', {key: 'signInPhoto', value: ''})
		},
		destroyed() {
			sessionStorage.removeItem('studyDone');
			this.player && this.player.destroy();
		},
		activated() {
			this.subject = this.$route.query.subject;
			this.courseId = this.$route.query.courseid;
			if (!this.signId) {
				this.getVideoList();
				this.signId = sessionStorage.getItem('signId');
			}
		},
		deactivated() {
			this.showMask1 = false;
			this.showMask2 = false;
			this.showMask3 = false;
			this.showPop = false;
			this.showPop1 = false;
			sessionStorage.removeItem('studyDone');
		},
		mounted() {
			window['receivePhotoResult'] = (base64) => {
				this.receivePhotoResult(base64);
			}
			window.getCameraData = (base64) => {
				console.log('uni-app.video 拍照回调', base64.substring(0, 10))
				this.receivePhotoResult(base64);
			}
			// this.signId = sessionStorage.getItem('signId');
			this.signId = this.$route.query.signId
			this.studyDone = sessionStorage.getItem('studyDone') || 0;
			const _this = this;
			if (!this.studyDone && this.studyDone != 0) {
				this.$utils.dialog.alert('课程信息错误，请重新签到进入', () => {
					sessionStorage.removeItem('signId')
					_this.$store.commit('cache/set_face', {key: 'signInPhoto', value: ''})
					_this.$router.replace('/train')
				})
			}
			this.subject = this.$route.query.subject;
			this.courseId = this.$route.query.courseid;
			this.getVideoList();

			// videojs
			// this.player = videojs(
			//   this.$refs.videoPlayer,
			//   this.playerOptions,
			//   function onPlayerReady() {
			//     console.log("onPlayerReady", this);
			//   }
			// );

			// this.player = this.$refs.videoRef;
			// this.$refs.videoRef.play()
			// console.log(this.player.duration, this.player.currentTime,this.player.readyState)
		},
		methods: {
			// 获取学习计划
			getPlanInfo() {
				getPlanInfo(this.userid).then(res => {
					console.log('res',res);
					this.mockTestFlag = res.mockTestFlag;
					this.playModeType = res.playModeType;
					this.belongOrganization = res.belongOrganization;
				})
			},
			chageBackGround(){
				switch(this.$store.state.user.categoryid){
					case 102:
						this.backGroundUrl = "https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/a4dff1ddf6b0907fff24a754ccb2851.jpg"
					break
					case 104:
						this.backGroundUrl = "https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/38ae5b263439aba6413c62acc3d1179.jpg"
					break
					case 105:
						this.backGroundUrl = "https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/580f0691cfb834c7a024546b4fc037f.jpg"
					break
					case 123:
					case 124:
					case 125:
					case 152:
					case 153:
					case 160:
					case 161:
					case 162:
						this.backGroundUrl = "https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/1b075d1eb701c92ff222d14b697274d.jpg"
					break
					default:
						this.backGroundUrl = "https://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/cover/d3f5ff377f40d1efde1c67769bf6874.jpg"
					break
				}
			},
			clickAppPlay() {
				const _this = this
				if (this.loading) return
				if (this.$store.state.cache.screen.wx) {
					wx.chooseImage({
						count: 1, // 默认9
						sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
						sourceType: ['camera'], // 可以指定来源是相册还是相机，默认二者都有
						success: function (res) {
							wx.uploadImage({
								localId: res.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
								isShowProgressTips: 1, // 默认为1，显示进度提示
								success: function (uploadRes) {
									wxCompareFace({mediaId: uploadRes.serverId}).then(() => {
										_this.uploading = false;
										// _this.$store.commit('cache/set_face', {
										// 	key: 'signInPhoto',
										// 	value: uploadRes.serverId
										// })
										if (_this.middleFace) { // 如果是中间验证人脸，验证完成之后，还原为false，如果是false，就走签退
											_this.middleFace = false
											_this.$utils.dialog.alert('验证成功，请继续学习')
										} else {
											_this.signBack({
												mediaId: uploadRes.serverId
											})
										}
										// _this.$router.push('/train')
									}).catch(() => {
										_this.uploading = false;
										// _this.$refs.imageInput.value = '';
										// _this.avatar = '';
									})
								},
								fail: function() {
									_this.uploading = false;
									_this.$utils.dialog.alert('上传照片失败，因微信使用内存不足，建议重启手机！');
								}
							})
						},
						fail: function() {
							_this.uploading = false;
							_this.$utils.dialog.alert('拍照失败，微信使用内存不足，建议重启手机！');
						}
					})
				} else {
					if (this.$store.state.cache.screen.isAndroid) {
						if (uni) {
							uni.postMessage({
								data: {
									action: 'setCamera'
								}
							});
							// return;
						}
						if (window.Android_JS && window.Android_JS.androidPhoto) {
							window.Android_JS.androidPhoto();
						}
					}
					if (this.$store.state.cache.screen.isIos) {
						if (uni) {
							uni.postMessage({
								data: {
									action: 'setCamera'
								}
							});
							// return;
						}
						if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosPhoto) {
							window.webkit.messageHandlers.iosPhoto.postMessage(null);
						}
					}
				}
			},
			receivePhotoResult(base64) {
				const _this = this;
				this.avatar = base64;
				this.loading = true;
				compareFace({photo: base64}).then(() => {
					if (_this.middleFace) { // 如果是中间验证人脸，验证完成之后，还原为false，如果是false，就走签退
						_this.middleFace = false
						_this.$utils.dialog.alert('验证成功，请继续学习')
					} else {
						_this.signBack({ base64 })
					}
					// _this.$store.commit('cache/set_face', {key: 'signOutPhoto', value: base64})
					// _this.signInOrOut()
					// _this.$router.push('/train')
				}).catch(() => {
					_this.uploading = false;
					// _this.$refs.imageInput.value = '';
					// _this.avatar = '';
				})
			},
			imgChange(e) {
				if (this.loading) return
				this.loading = true;
				let file = e.target.files[0];
				const _this = this;
				new Compressor(file, {
					quality: 0.9,
					maxWidth: 413,
					maxHeight: 626,
					convertTypes: ['image/jpeg'],
					convertSize: 1000000,
					success(result) {
						console.log('video照片压缩成功');
						let reader = new FileReader();
						reader.readAsDataURL(result);
						reader.onload = (event) => {
							console.log('video执行compareFace');
							compareFace({photo: event.target.result}).then(() => {
								_this.loading = false;
								if (_this.middleFace) { // 如果是中间验证人脸，验证完成之后，还原为false，如果是false，就走签退
									_this.middleFace = false
									_this.$utils.dialog.alert('验证成功，请继续学习')
								} else {
									_this.signBack({ base64: event.target.result })
								}
							}).catch(() => {
								_this.loading = false;
								_this.$refs.imageInput.value = '';
								_this.uploading = false;
								_this.avatar = '';
							})
						};
						_this.avatar = window.URL.createObjectURL(result)
					},
					error(err) {
						console.log(err);
						_this.$utils.dialog.alert('照片压缩时出错，请重拍');
					}
				});
			},
			calcHeight() {
				return `height: calc(100vh - ${210 + this.navHeight}px)`
			},
			getBrowserInfo() {
				var agent = navigator.userAgent.toLowerCase();
				var arr = [];
				var system = agent.split(' ')[1].split(' ')[0].split('(')[1];
				arr.push(system);
				var regStr_edge = /edge\/[\d.]+/gi;
				var regStr_ie = /trident\/[\d.]+/gi;
				var regStr_ff = /firefox\/[\d.]+/gi;
				var regStr_chrome = /chrome\/[\d.]+/gi;
				var regStr_saf = /safari\/[\d.]+/gi;
				var regStr_opera = /opr\/[\d.]+/gi;
				//IE
				if (agent.indexOf("trident") > 0) {
					arr.push(agent.match(regStr_ie)[0].split('/')[0]);
					arr.push(agent.match(regStr_ie)[0].split('/')[1]);
					return arr;
				}
				//Edge
				if (agent.indexOf('edge') > 0) {
					arr.push(agent.match(regStr_edge)[0].split('/')[0]);
					arr.push(agent.match(regStr_edge)[0].split('/')[1]);
					return arr;
				}
				//firefox
				if (agent.indexOf("firefox") > 0) {
					arr.push(agent.match(regStr_ff)[0].split('/')[0]);
					arr.push(agent.match(regStr_ff)[0].split('/')[1]);
					return arr;
				}
				//Opera
				if (agent.indexOf("opr") > 0) {
					arr.push(agent.match(regStr_opera)[0].split('/')[0]);
					arr.push(agent.match(regStr_opera)[0].split('/')[1]);
					return arr;
				}
				//Safari
				if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
					arr.push(agent.match(regStr_saf)[0].split('/')[0]);
					arr.push(agent.match(regStr_saf)[0].split('/')[1]);
					return arr;
				}
				//Chrome
				if (agent.indexOf("chrome") > 0) {
					arr.push(agent.match(regStr_chrome)[0].split('/')[0]);
					arr.push(agent.match(regStr_chrome)[0].split('/')[1]);
					return arr;
				} else {
					arr.push('请更换主流浏览器，例如chrome,firefox,opera,safari,IE,Edge!')
					return arr;
				}
			},
			/**
			 * 签退逻辑 用于替代 signInOrOut
			 * */
			signBack ({ base64 = '', mediaId = '' }) {
				const _this = this
				signOut(this.signId, base64, mediaId).then(() => {
					_this.signId = '';
					_this.showMask1 = false;
					_this.showMask2 = false;
					_this.showMask3 = false;
					_this.showPop = false;
					_this.showPop1 = false;
					_this.loading = false;
					sessionStorage.removeItem('signId');
					_this.player && _this.player.destroy();
					_this.$router.replace('/train');
				}).catch(() => {
					_this.loading = false;
				})
			},
			signInOrOut() {
				if (!this.signId) {
					// this.signIn();
					const _this = this;
					// this.signIn();
					this.loading = false;
					this.$dialog.alert({
						message: '签到照片为空，未避免影响学时，请前往首页重新签到进入',
						theme: 'round-button',
						confirmButtonColor: '#fca142',
						width: 290,
					}).then(() => {
						_this.$router.replace('/train')
					})
				} else {
					// if(!this.player) {
					//   this.$dialog.alert({
					//     message: '请先学习再签退',
					//     theme: 'round-button',
					//     confirmButtonColor: '#fca142',
					//     width: 290,
					//   });
					//   return;
					// }
					// this.watchLength = Math.floor(this.player.getPosition());
					signOut(this.signId, this.avatar).then(() => {
						this.signId = '';
						this.showMask1 = false;
						this.showMask2 = false;
						this.showMask3 = false;
						this.showPop = false;
						this.showPop1 = false;
						this.loading = false;
						sessionStorage.removeItem('signId');
						this.player && this.player.destroy();
						// this.$destroy('videocomp');
						this.navBack();
					}).catch(() => {
						this.loading = false;
					})
				}
			},
			signIn() {
				this.loading = true;
				const info = this.getBrowserInfo();
				const type = (info[0].includes('iphone') || info[0].includes('ipad')) ? '苹果' : '安卓'
				signIn({
					studentId: this.userid,
					belongCategory: this.subject,
					unitType: type,
					signInIp: window.location.hostname,
				}).then((res) => {
					this.signId = res.basePkId;
					sessionStorage.setItem('signId', this.signId);
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
				})
			},
			upload(file) {
				if (this.avatar) return;

				const _file = file;
				const isLt2M = _file.size / 1024 / 1024 < 20;
				if (!isLt2M) {
					this.showAlert("请上传20M以下图片");
					return false;
				}

				// 通过 FormData 对象上传文件
				let formData = new FormData();
				formData.append("photo", _file);
				this.loading = true;
				compareFace(formData).then((data) => {
					this.avatar = data.url;
					this.signInOrOut();
				}).catch(() => {
					this.loading = false;
					this.$refs.imageInput.value = '';
					this.avatar = '';
				})
			},
			/**
			 * 20s 保存一次学时的节流方法 （不用闭包。太费内存）
			 * @param { number | string } wait： 执行时间 (number) 是否停止定时器 (string: clear)
			 */
			timingSubmitStudyThrittle(wait) {
				const _this = this;
				if (typeof wait === 'string' && wait === 'clear') {
					if (this.submitStudyTimer) {
						clearTimeout(_this.submitStudyTimer)
						this.submitStudyTimer = null
					}
				} else if (typeof wait === 'number') {
					if (!this.submitStudyTimer) {
						this.submitStudyTimer = setTimeout(() => {
							_this.timingSubmitStudy();
							_this.submitStudyTimer = null
							_this.timingSubmitStudyThrittle(wait)
						}, wait)
					}
				}
			},
			// 提交学时，20S一次，暂停时、播放结束时，均需保存学时
			timingSubmitStudy(/*...data */) {
				const _this = this;
				viewLogPush({
					chapterId: this.playedVideo.basePkId /* '章节ID' */,
					courseId: this.$route.query.courseId /*'课程ID'*/,
					planId: this.planid /* '学员计划ID'*/,
					signId: this.signId /* '签到ID'*/,
					studentId: this.userid /* '学员ID'*/,
					videoId: this.playedVideo.videoId /* '视频ID' */,
					videoLength: this.videoLength /* '视频总长度' */,
					watchLength: this.player.getPosition() || 0 /* '当前观看长度' */
				}).then(res => {
					if (res.signTime) {
						_this.player.pause();
						_this.player.normalScreen();
						_this.middleFace = true
						_this.$utils.dialog.alert({
							message: '需要验证人脸，请进行人脸识别',
							showCancelButton: false,
							closeOnClickOverlay: false
						}, () => {
							// 此处 调用APP 摄像头方法
							if (_this.isUseJSPlayPhoto) {
								_this.clickAppPlay()
							} else {
								_this.$refs.imageInput.click()
							}
						})
					}
				}).catch(() => {
					_this.player.pause();
					clearTimeout(_this.submitStudyTimer)
				})
			},
			// 切换章
			changeChapter(idx) {
				// if(this.canPractice&&(this.activeChapter+1 == idx||idx<this.activeChapter)) {
				//   this.activeChapter = idx;
				// }
				if (this.videoList[idx].unlock || idx == 0 || this.nextWatchVideo.done) { // 已解锁
					this.activeChapter = idx;
				}
				if (this.canPractice) {
					if (this.activeChapter + 1 == idx) {
						this.activeChapter = idx;
						this.$set(this.videoList[idx], 'unlock', true);
					}
					this.activeSection = 0;
					this.showMask2 = false;
					this.showMask3 = false;
				}
				this.checkCanPractice();
			},
			seeAll(idx) {
				if (this.videoList[this.activeChapter].children) {
					let auto = !!this.videoList[this.activeChapter].children[idx].auto;
					// this.videoList[this.activeChapter].children[idx].auto = !auto;
					this.$set(this.videoList[this.activeChapter].children[idx], `auto`, !auto)
				}
			},
			getVideoList() {
				getVideoList({
					subject: this.subject,
					studentId: this.userid,
					courseId: this.courseId,
				}).then((res) => {
					// this.practiceVideo = res.find(v => v.type==4);
					this.videoList = this.treeDataTranslate(res, 'basePkId', 'parentPkId')
					console.log(this.videoList)
					// 记录上次观看
					let lastWatchVideo = {}, nextWatchVideo = {};
					this.videoList.map((chapter, ci) => {
						if (chapter.children) {
							chapter.children.map((section, si) => {
								if (section.children) {
									section.children.map((video, vi) => {
										if (video.video && video.watchedFlag == 1) {
											this.activeChapter = ci;
											this.activeSection = si;
											this.activeVideo = vi;
											lastWatchVideo = video;
											// 记录下一个
											if (vi + 1 <= section.children.length - 1) {
												this.nextActiveChapter = ci;
												this.nextActiveSection = si;
												this.nextActiveVideo = vi + 1;
												nextWatchVideo = section.children[vi + 1]
											} else if ((si + 1 <= chapter.children.length - 1) && chapter.children[si + 1].children) {
												this.nextActiveChapter = ci;
												this.nextActiveSection = si + 1;
												this.nextActiveVideo = 0;
												nextWatchVideo = chapter.children[si + 1].children[0]
											} else if ((ci + 1 <= this.videoList.length - 1) && this.videoList[ci + 1].children && this.videoList[ci + 1].children[0].children) {
												this.nextActiveChapter = ci + 1;
												this.nextActiveSection = 0;
												this.nextActiveVideo = 0;
												nextWatchVideo = this.videoList[ci + 1].children[0].children[0]
											} else { // 全部已学习
												nextWatchVideo = {done: true};
											}
										}
									})
								}
							})
						}
					})
					this.playedVideo = lastWatchVideo;
					this.reviewCCID = lastWatchVideo.video ? lastWatchVideo.video.videoCcid : '';
					this.reviewName = lastWatchVideo.video ? lastWatchVideo.video.videoName : '';
					if (lastWatchVideo.video) {
						this.showMask3 = true;
						this.nextWatchVideo = nextWatchVideo;
					} else {
						// this.onCCH5PlayerLoaded();
						this.playVideo(this.videoList[0].children[0].children[0], 0, 0)
					}

					for (let index = 0; index <= this.activeChapter; index++) {
						if (this.videoList.length) {
							this.$set(this.videoList[index], 'unlock', true);
						}
					}

					this.checkCanPractice();
				})
			},
			showVideoType() {
				this.showMask3 = true;
			},
			hideVideoType() {
				this.showMask3 = false;
			},
			checkBackSection(idx) {
				// if(idx == 0)
				if (this.videoList[this.activeChapter].children[idx].children) {
					if (this.videoList[this.activeChapter].children[idx].type != 2) return false;
					// let videoLen = this.videoList[this.activeChapter].children[idx].children.length;
					if (this.videoList[this.activeChapter].children[idx].children.find(v => v.watchedFlag == 0)) {
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}
			},
			checkCanPractice() {
				if (!this.videoList.length) return;
				if (this.videoList[this.activeChapter].children) {
					let sectionLen = this.videoList[this.activeChapter].children.length;
					let flag = false;
					for (let index = sectionLen - 1; index >= 0; index--) {
						if (this.videoList[this.activeChapter].children[index].children) {
							if (this.videoList[this.activeChapter].children[index].type != 2) continue;
							// let videoLen = this.videoList[this.activeChapter].children[index].children.length;
							if (this.videoList[this.activeChapter].children[index].children.find(v => v.watchedFlag == 0)) {
								flag = true;
								break;
							} else {
								flag = false;
							}
						}
					}
					this.canPractice = !flag;
				} else {
					this.canPractice = true;
				}
				return false;
			},
			navToPractice(id) {
				if (this.canPractice) {
					// if (this.signId) {
					//   this.showPop1 = true;
					//   return;
					// }
					this.$router.push({path: "/exam", query: {id, subject: this.subject}});
				}
			},
			// 考试
			navToTest() {
				// if (this.nextActiveVideo.done || (this.canPractice && this.activeChapter == this.videoList.length - 1)) {
				if (this.videoList[this.videoList.length - 1].watchedFlag==='1' && this.mockTestFlag === '1') {
				// if (this.nextActiveVideo.done || (this.canPractice && this.videoList[this.videoList.length - 1].watchedFlag==='1')) {
					// if (this.signId) {
					//   this.showPop1 = true;
					//   return;
					// }
					this.$router.push({path: "/test", query: {subject: this.subject}});
				}
			},
			back() {
				if (!this.signId) {
					this.showPop = false;
					this.showPop1 = false;
					this.navBack();
					sessionStorage.removeItem('signId');
					return;
				}
				this.showPop = true;
			},
			cancle() {
				this.showPop = false;
				this.showPop1 = false;
			},
			confirm() { // 确认退出
				// if(this.signId) {
				//   this.showPop = false;
				//   this.showPop1 = false;
				//   return;
				// }
				sessionStorage.removeItem('signId');
				this.signId = '';
				this.navBack();
			},
			navBack() {
				this.showMask1 = false;
				this.showMask2 = false;
				this.showMask3 = false;
				this.showPop = false;
				this.showPop1 = false;
				this.activeChapter = 0;
				this.activeSection = 0;
				this.activeVideo = 0;
				this.player && this.player.destroy();
				this.reviewer = null;
				this.player = null;
				this.$nextTick(() => {
					this.$router.push({path: "/train"});
				})
			},
			// ccplayer
			replay() {
				this.playVideo(this.playedVideo, this.activeSection, this.activeVideo)
				if (!this.signId) return;
				this.showMask3 = false;
			},
			playNext() {
				this.activeChapter = this.nextActiveChapter;
				this.playVideo(this.nextWatchVideo, this.nextActiveSection, this.nextActiveVideo)
				if (!this.signId) return;
				this.showMask3 = false;
			},
			playVideo(video, index, i) {
				console.log(video, index, i)
				if (this.studyDone == 0 && !this.signId) {
					this.$dialog.alert({
						message: '请先签到再学习',
						theme: 'round-button',
						confirmButtonColor: '#fca142',
						width: 290,
					});
					return;
				}
				if (!video) return;
				if (i != 0) {
					const nextVideo = this.videoList[this.activeChapter].children[index].children[i - 1];
					if (nextVideo && nextVideo.watchedFlag == 0) {
						this.$dialog.alert({
							message: '请按顺序进行学习',
							theme: 'round-button',
							confirmButtonColor: '#fca142',
							width: 290,
						});
						return;
					}
				} else {
					const lastSection = this.videoList[this.activeChapter].children[index - 1];
					if (lastSection && lastSection.children[lastSection.children.length - 1].watchedFlag == 0) {
						this.$dialog.alert({
							message: '请按顺序进行学习',
							theme: 'round-button',
							confirmButtonColor: '#fca142',
							width: 290,
						});
						return;
					}
				}
				this.playedVideo = video;
				// this.activeChapter = index;
				this.activeSection = index;
				this.activeVideo = i;
				this.reviewCCID = video.video.videoCcid;
				this.reviewName = video.video.videoName;
				this.onCCH5PlayerLoaded();
				this.showMask2 = false;
				this.showMask3 = false;
			},
			// logo: {
			// 	url: 'https://img2.baidu.com/it/u=2884414087,769982695&fm=26&fmt=auto&gp=0.jpg',
			// 	opacity: 70
			// }
			createPlayer() {
				this.reviewer = document.getElementById("reviewer");
				console.log('nextWatchVideo===>', this.nextWatchVideo)
				// window.createCCH5Player({}).player.destroy()

				this.player = window.createCCH5Player({
					vid: this.reviewCCID,
					siteid: window.reviewerSiteId,
					autoStart: false,
					showRateBtn: false,
					banDrag: false,
					playtype: 1,
					useX5Play: true, // 同层播放
					// rate_array: [1],
					width: "100%",
					height: "210",
					parentNode: this.reviewer,
					rate_allow_change: false,
					// allowFullScreen: false
				});
			},
			setPlayerCallBack() {
				window.onCCH5PlayerLoaded = this.onCCH5PlayerLoaded
				window.on_CCH5player_play = this.on_CCH5player_play
				window.on_CCH5player_pause = this.on_CCH5player_pause
				window.on_CCH5player_ended = this.on_CCH5player_ended
				window.on_switch_rate = this.on_switch_rate
				window.on_CCH5player_ready = this.onPlayerReady;
				window.on_player_volumechange = this.onPlayerVolumeChange;
			},
			onCCH5PlayerLoaded() {
				window.ccH5PlayerJsLoaded = true;
				window.reviewerSiteId = 'D9CDA78D56C216BD';
				if (this.reviewCCID) this.createPlayer();
			},
			on_switch_rate (vid, newRate){
				console.log("倍速回调测试")
				console.log(vid, newRate)
			},
			on_CCH5player_play() {
				console.log(this.playedVideo)
				this.videoLength = this.player.getDuration();
				// if (typeof this.videoLength !== 'number') {
				// 	this.$utils.dialog.alert('请当日学习完成后及时签退，否则学时无效')
				// 	this.player.pause()
				// 	return
				// }
				// this.$set(this.videoList[this.activeChapter].children[this.activeSection].children[this.activeVideo], `duration`, this.videoLength)
				// 学习完 不签退 不签到 不调其它接口
				if (this.studyDone == 1) return;
				watchVideo({
					studentId: this.userid,
					signId: this.signId,
					videoId: this.playedVideo.videoId,
					chapterId: this.playedVideo.basePkId,
					courseId: this.courseid,
					watchLength: 0,
					videoLength: this.playedVideo.video.videoDuration
				})
				// 开启节流 20S 一次
				this.timingSubmitStudyThrittle(20000);
			},
			// 暂停播放
			on_CCH5player_pause() {
				console.log('视频暂停')
				this.timingSubmitStudyThrittle('clear');
				this.timingSubmitStudy();
			},
			on_CCH5player_ended() {
				// 学习完 不签退 不签到 不调其它接口
				if (this.studyDone == 1) return;
				this.timingSubmitStudyThrittle('clear');
				watchVideo({
					studentId: this.userid,
					signId: this.signId,
					videoId: this.playedVideo.videoId,
					chapterId: this.playedVideo.basePkId,
					courseId: this.courseid,
					watchLength: this.playedVideo.video.videoDuration, // this.player.getPosition() || 0,
					videoLength: this.playedVideo.video.videoDuration // this.videoLength || this.player.getDuration() || 0,
				}).then(() => {
					this.$set(this.videoList[this.activeChapter].children[this.activeSection].children[this.activeVideo], `watchedFlag`, 1)
					// if (this.activeVideo >= this.videoList[this.activeChapter].children[this.activeSection].children.length - 1) {
					//   this.canPractice = true;
					// }
					this.checkCanPractice();
				})
			},
			onPlayerReady() {
				// window.cc_js_Player.setVolume(0.3);
				if (this.playModeType === '1') {
					setTimeout(() => {
						const e = document.createEvent('MouseEvents')
						e.initEvent('click', true, true)
						document.getElementById('replaybtn').dispatchEvent(e)
					}, 1000)
				}
			},
			onPlayerVolumeChange(volume, vid) {
				console.log('设置音量回调:  ', volume, vid)
			},
			/**
			 * 树形数据转换
			 * @param {*} data
			 * @param {*} id
			 * @param {*} pid
			 */
			treeDataTranslate(data, id = 'id', pid = 'parentId') {
				var res = []
				var temp = {}
				for (var i = 0; i < data.length; i++) {
					temp[data[i][id]] = data[i]
				}
				for (var k = 0; k < data.length; k++) {
					if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
						if (!temp[data[k][pid]]['children']) {
							temp[data[k][pid]]['children'] = []
						}
						if (!temp[data[k][pid]]['_level']) {
							temp[data[k][pid]]['_level'] = 1
						}
						data[k]['_level'] = temp[data[k][pid]]._level + 1
						temp[data[k][pid]]['children'].push(data[k])
					} else {
						res.push(data[k])
					}
				}
				return res
			}
		},
	};
</script>

<style lang="less" scoped>
	.video-wrap {
		position: relative;
		max-height: 250px;
		width: 100%;
		overflow-x: hidden;

		.head {
			position: absolute;
			top: 10px;
			width: 100%;
			z-index: 1600;

			.video-type {
				background: rgba(0, 0, 0, 0.3);
				border-radius: 20px;
				font-size: 12px;
				padding: 4px 8px;
			}
		}

		video {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		.video-tool {
			display: flex;
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 10px 20px;
			background: rgba(0, 0, 0, 0.3);
			color: #fff;
			z-index: 9;

			.left-tool {
				display: flex;
			}

			.center {
				flex: auto;
				display: flex;
				align-items: center;
				padding: 0 4px;

				.line {
					flex: auto;
					height: 4px;
					background: rgba(255, 255, 255, 0.4);
					border-radius: 4px;

					.inner-line {
						position: relative;
						width: 50%;
						background: #fff;
						border-radius: 4px;
						height: 100%;

						&::before {
							position: absolute;
							top: 50%;
							right: 0;
							margin-top: -5px;
							margin-right: -5px;
							content: "";
							width: 10px;
							height: 10px;
							border-radius: 10px;
							// background: #ff8506;
							box-shadow: 0 0 6px 0 rgba(255, 255, 255, 0.3);
						}
					}
				}

				.time {
					padding: 0 8px;
				}
			}

			.right-tool {
				display: flex;
			}
		}

		.video-mask {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 1);
			z-index: 1599;
			transition: all 0.3s ease;

			.exam-wrap {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 80%;
				padding: 20px;
				margin: auto;

				p {
					margin-bottom: 20px;
					line-height: 1.6;
				}

				.btn-wrap {
					display: flex;
					justify-content: space-evenly;

					.btn {
						width: auto;
						padding: 0 20px;
						height: 34px;
						line-height: 34px;
						font-size: 14px;
						margin: 0;

					}
				}

				&.tips {
					.btn {
						background: transparent;
						border: 1px solid #fff;
						color: #fff;
					}
				}

				&.video-type {
					.btn {
						width: 48px;
						height: 48px;
						line-height: 48px;
						background: rgba(0, 0, 0, 0.8);
						color: #fff;
						border: 1px solid transparent;
						border-radius: 48px;
						padding: 0;
						font-weight: 500;

						&.active {
							color: #e6c344;
						}
					}
				}
			}
		}
	}

	.video-content {
		display: flex;
		flex-direction: column;
		height: calc(100vh - 210px);

		.right-side {
			position: relative;

			.img-input {
				position: absolute;
				top: 0;
				width: 100%;
				left: 0;
				opacity: 0;
				height: 100%;
			}
		}
	}

	.title {
		font-size: 17px;

		&.line {
			padding-left: 10px;
		}
	}

	.list {
		position: relative;
		display: flex;
		align-items: center;
		padding: 6px 10px;
		height: 50px;

		span {
			display: inline-block;
			flex: auto;
		}

		.btn {
			width: 80px;
			font-size: 14px;
			height: 34px;
		}
	}

	.video-chapter {
		display: flex;
		flex: auto;
		background: #F1F2F3;
		padding-top: 8px;
		height: calc(100% - 50px);

		.left {
			background: #fff;
			flex: 0 0 100px;
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto;

			.chapter-list {
				padding: 10px 0;

				.chapter-item {
					display: flex;
					align-items: center;
					// justify-content: center;
					padding: 5px;
					font-size: 12px;

					&.active {
						color: #ff8506;
					}

					img {
						width: 12px;
						margin-right: 2px;
					}

					span {
						display: inline-block;
						max-width: 60px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}
			}
		}

		.right {
			flex: auto;
			height: 100%;
			border-radius: 8px;
			overflow-x: hidden;
			overflow-y: auto;
			margin: 0 10px;

			.section-wrap {
				// background: #ffffff;
				border-radius: 8px;
			}

			.chapter-title {
				font-size: 17px;
				padding: 20px 0 0;
				text-align: center;
				color: #ff8506;
				background: #ffffff;

				& + .section {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}
			}

			.section {
				position: relative;
				padding: 20px 15px 30px;
				border-radius: 8px;
				background: #ffffff;
				margin-bottom: 10px;

				.title {
					display: inline-block;
					margin-bottom: 10px;
				}

				.section-list {
					display: flex;
					flex-wrap: wrap;
					transition: all 0.3s ease;
					overflow-y: hidden;

					.section-item {
						width: 48%;
						// margin-bottom: 4px;
						&:nth-of-type(2n+1) {
							margin-right: 4%;
						}

						&.no_study .top::after{
							content: '';
							display: block;
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							z-index: 90;
							background-color: rgba(0, 0, 0, .7)
						}
						.top {
							height: 72px;
							background: #fca142;
							background-size: cover;
							border-radius: 4px;
							position: relative;

							.status {
								position: absolute;
								top: 4px;
								left: 0;
								background: rgba(255, 255, 255, 0.9);
								color: #ff8506;
								border-top-right-radius: 20px;
								border-bottom-right-radius: 20px;
								font-size: 8px;
								padding: 0px 6px;

								&.unread {
									background: rgba(212, 212, 212, .86);
									color: rgba(250, 248, 248, 1);
								}
							}

							.video-name {
								position: absolute;
								top: 50%;
								left: 50%;
								width: 100px;
								transform: translate(-50%, -50%);
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								background: rgba(255, 255, 255, 0.9);
								color: #ff8506;
								font-size: 10px;
								padding: 2px 4px;
								text-align: center;
								border-radius: 20px;
							}

							.duration {
								position: absolute;
								bottom: 4px;
								right: 6px;
								color: #fff;
								font-size: 9px;
								padding: 1px;
							}
						}

						span {
							display: inline-block;
							padding: 4px 10px;
							max-width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}

					.section-button {
						position: absolute;
						bottom: 0;
						border-radius: 8px;
						text-align: center;
						line-height: 30px;
						color: #ff8506;
						font-size: 10px;
						left: 50%;
						transform: translateX(-50%);
						padding: 0 20px;

						a {
							text-decoration: underline;
						}
					}
				}
			}

			.read-status {
				display: flex;
				align-items: center;
				justify-content: space-around;
				color: #ffffff;
				border-radius: 8px;
				width: 100%;
				height: 50px;
				font-size: 11px;
				padding: 0;
				background-color: transparent;
				background-image: url(~@/assets/icon-bg-normal.png);
				background-size: cover;
				background-position: center center;

				.btn {
					width: auto;
					height: 30px;
					line-height: 30px;
					font-size: 10px;
					margin: 0;
					padding: 0 10px;
					background: #ffffff;
					color: #ff8506;
					border-color: transparent;
					flex-shrink: 0;
				}

				&.transparent {
					opacity: .7;
					// color: #666;
					// background-image: url(~@/assets/icon-bg-transparent.png);
					.btn {
						background: #fca142;
						color: #fff;
						opacity: 0.7;
					}
				}
			}
		}
	}

	.popup-content {
		width: 290px;
		padding: 20px;

		.content {
			line-height: 1.8;
			margin-bottom: 20px;
		}

		.btn-wrap {
			display: flex;
			justify-content: space-evenly;
			width: 100%;

			.btn {
				width: 40%;
			}

			.right-side {
				width: 40%;
				position: relative;
				margin: auto;

				.btn {
					width: 100%;
				}

				.img-input {
					position: absolute;
					top: 0;
					width: 100%;
					left: 0;
					opacity: 0;
					height: 100%;
				}
			}
		}
	}

	.APP02 {
		@colorMain: #2db1ac;
		@colorSecond: #48d8a0;

		.video-chapter {
			.left {
				.chapter-list {
					.chapter-item {
						&.active {
							color: @colorMain;
						}
					}
				}
			}

			.right {
				.chapter-title {
					color: @colorMain;
				}

				.section {
					.section-list {
						.section-item {
							.top {
								background: @colorSecond;

								.status {
									color: @colorSecond;
								}

								.video-name {
									color: @colorSecond;
								}
							}
						}
					}
				}

				.read-status {
					.btn {
						background: #ffffff !important;
						color: @colorMain;
					}

					&.transparent {
						.btn {
							background: @colorSecond;
						}
					}
				}
			}
		}
	}

	.APP03 {
		@colorMain: #1175fe;
		@colorSecond: #3fadfa;

		.video-chapter {
			.left {
				.chapter-list {
					.chapter-item {
						&.active {
							color: @colorMain;
						}
					}
				}
			}

			.right {
				.chapter-title {
					color: @colorMain;
				}

				.section {
					.section-list {
						.section-item {
							.top {
								background: @colorSecond;

								.status {
									color: @colorSecond;
								}

								.video-name {
									color: @colorSecond;
								}
							}
						}
					}
				}

				.read-status {
					.btn {
						background: #ffffff !important;
						color: @colorMain;
					}

					&.transparent {
						.btn {
							background: @colorSecond;
						}
					}
				}
			}
		}
	}
</style>
