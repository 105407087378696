<template>
	<div class="content-wrap">
		<van-form @submit="changeStep">
			<div class="section-top">
				<h4 class="title">
					<span class="txt"><span class="line"></span>填写身份信息<span class="line"></span></span>
				</h4>
				<div class="form">
					<!-- city -->
					<van-cell-group :border="false" @click="navToCity()" v-if="state.showCity">
						<van-field :rules="[{ required: true, message: '请选择城市' }]" label="" placeholder="请选择您学车所在城市"
								   v-model="form.city" readonly>
							<img
								slot="left-icon"
								class="icon-img"
								:src="require('../../assets/icon/icon-location-sm'+(appno==1?'':appno)+'.png')"
								alt="地市"
							/>
						</van-field>
					</van-cell-group>
					<!-- studentName -->
					<van-field
						v-model="form.studentName"
						clearable
						label=""
						:rules="[{ required: true, message: '请输入真实姓名' }]"
						placeholder="请输入真实姓名"
					>
						<img
							slot="left-icon"
							class="icon-img"
							:src="require('../../assets/icon/icon-man-sm'+(appno==1?'':appno)+'.png')"
							alt="姓名"
						/>
					</van-field>
					<!-- certNum -->
					<van-field
						v-if="!state.isIdCard"
						v-model="form.certNum"
						clearable
						maxlength="18"
						:rules="[{ required: true, pattern: idCardPattern, message: '请输入正确的证件号' }]"
						label=""
						placeholder="请输入报名的证件号"
					>
						<img
							slot="left-icon"
							class="icon-img"
							:src="require('../../assets/icon/icon-idcard-sm'+(appno==1?'':appno)+'.png')"
							alt="证件号"
						/>
					</van-field>
					<!-- mobile -->
					<van-field
						v-if="!state.isPhone && !(form.appNo==='APP02' && form.cityCode.substring(0,2)==='21')"
						v-model="form.mobile"
						clearable
						type="tel"
						maxlength="11"
						:rules="[{ required: false, validator: mobileValidator, message: '请输入正确的手机号' }]"
						label=""
						placeholder="请输入报名的手机号"
					>
						<img
							slot="left-icon"
							class="icon-img"
							:src="require('../../assets/icon/icon-mobile-sm'+(appno==1?'':appno)+'.png')"
							alt="手机号"
						/>
					</van-field>
					<!-- 乌海 -->
					<van-field name="isMakeUpStudy" v-if="state.showMakeUpStudy">
						<template #input>
							<div style="margin-right:5px; width:90px;"><span style="color:#E10900;">此次学习</span></div>
							<van-radio-group v-model="form.isMakeUpStudy" direction="horizontal">
								<van-radio name="0" @click="form.isMakeUpStudy='0'" style="margin-bottom: 5px;">
									出租车继续教育课程
								</van-radio>
								<van-radio name="1" @click="form.isMakeUpStudy='1'" style="margin-bottom: 5px;">
									出租车三年未上岗再教育
								</van-radio>
								<div><span style="color:#E10900;">非出租车类请忽略上述选项</span></div>
							</van-radio-group>
						</template>
					</van-field>
					<!-- 继续教育：辽宁东宇 -->
					<van-field name="isChooseTrainType" v-if="form.appNo==='APP02' && form.cityCode.substring(0,2)==='21'">
						<template #input>
							<div style="margin-right:5px; width:90px;"><span style="color:#E10900;">学习课程</span></div>
							<van-radio-group v-model="form.trainType" direction="vertical">
<!--								<van-radio name="001" @click="form.trainType='001'" style="margin-bottom: 15px;">-->
<!--									道路旅客运输驾驶员-->
<!--								</van-radio>-->
<!--								<van-radio name="002" @click="form.trainType='002'" style="margin-bottom: 15px;">-->
<!--									道路货物运输驾驶员-->
<!--								</van-radio>-->
<!--								<van-radio name="101" @click="form.trainType='101'" style="margin-bottom: 15px;">-->
<!--									道路危险货物运输驾驶员-->
<!--								</van-radio>-->
<!--								<van-radio name="004" @click="form.trainType='004'" style="margin-bottom: 15px;">-->
<!--									出租车驾驶员-->
<!--								</van-radio>-->
								<van-radio name="005" @click="form.trainType='005'" style="margin-bottom: 15px;">
									巡游、网约出租车驾驶员
								</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<!-- 初学：常州 -->
					<van-field v-if="form.appNo==='APP01' && form.cityCode==='320400'">
						<template #input>
							<div style="margin-right:5px; width:90px;"><span style="color:#E10900;">培训车型</span></div>
							<van-radio-group v-model="form.trainType" direction="vertical">
								<van-radio name="102" @click="form.trainType='102'" style="margin-bottom: 15px;">
									小车(C1/C2/C3)
								</van-radio>
								<van-radio name="104" @click="form.trainType='104'" style="margin-bottom: 15px;">
									货车(A2/B2)
								</van-radio>
								<van-radio name="105" @click="form.trainType='105'" style="margin-bottom: 15px;">
									客车(A1/A3/B1)
								</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<!-- 初学：济南、江西省、云南省 -->
					<van-field v-if="form.appNo==='APP01' && (form.cityCode==='370100' || form.cityCode.substring(0,2)==='36' || form.cityCode.substring(0,2)==='53')">
						<template #input>
							<div style="margin-right:5px; width:90px;"><span style="color:#E10900;">培训车型</span></div>
							<van-radio-group v-model="form.trainType" direction="vertical">
								<van-radio name="C1" @click="form.trainType='C1'" style="margin-bottom: 15px;">
									小车(C1)
								</van-radio>
								<van-radio name="C2" @click="form.trainType='C2'" style="margin-bottom: 15px;">
									小车(C2)
								</van-radio>
								<van-radio name="C3" @click="form.trainType='C3'" style="margin-bottom: 15px;">
									小车(C3)
								</van-radio>
								<van-radio name="C6" @click="form.trainType='C6'" style="margin-bottom: 15px;" v-if="form.cityCode.substring(0,2)==='53'">
									小车(C6)
								</van-radio>
								<van-radio name="A22" @click="form.trainType='A22'" style="margin-bottom: 15px;">
									货车(A2)
								</van-radio>
								<van-radio name="B22" @click="form.trainType='B22'" style="margin-bottom: 15px;">
									货车(B2)
								</van-radio>
								<van-radio name="A11" @click="form.trainType='A11'" style="margin-bottom: 15px;">
									客车(A1)
								</van-radio>
								<van-radio name="B11" @click="form.trainType='B11'" style="margin-bottom: 15px;">
									客车(B1)
								</van-radio>
								<van-radio name="A33" @click="form.trainType='A33'" style="margin-bottom: 15px;">
									客车(A3)
								</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<!-- 继续教育：常州教练员1 -->
					<van-field v-if="form.appNo==='APP02' && form.cityCode==='320400'">
						<template #input>
							<div style="margin-right:5px; width:90px;"><span style="color:#E10900;">培训类型</span></div>
							<van-radio-group v-model="form.trainType" direction="vertical">
								<van-radio name="001" @click="form.trainType='001'" style="margin-bottom: 15px;">
									教练员继续教育
								</van-radio>
								<van-radio name="110" @click="form.trainType='110'" style="margin-bottom: 15px;">
									货运A2/B2
								</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<!-- 继续教育：常州教练员2 -->
					<van-field v-if="form.appNo==='APP02' && form.cityCode==='320400' && form.trainType==='001'"
						v-model="corpName"
						name="corpName"
						label="所属驾校"
						placeholder="请选择所属驾校"
						readonly
						@click="openIndexBar"
					/>
					<!-- 继续教育：常州教练员3 -->
					<van-field v-if="form.appNo==='APP02' && form.cityCode==='320400' && form.trainType==='001'"
					   v-model="trainYear"
					   name="trainYear"
					   label="培训年度"
					   readonly
					/>
					<p v-if="form.appNo==='APP05' && form.cityCode.includes('37') && state.isIdCard && isShow" style="text-align:center"><b>核对资格证所属地市及姓名，确保无误后，点击</b><b style="font-size:150%; color:red" @click="findStaffInfo(),show = true"><u>获取资格证信息</u></b></p>
					<p v-if="form.appNo==='APP05' && form.cityCode.includes('37') && !state.isIdCard && isShow" style="text-align:center"><b>核对资格证所属地市、姓名及身份证号，确保无误后，点击</b><b style="font-size:150%; color:red" @click="findStaffInfo(),show = true"><u>获取资格证信息</u></b></p>
					<p v-if="form.appNo==='APP02' && form.cityCode.includes('37') && state.isIdCard && isShow" style="text-align:center"><b>核对资格证所属地市及姓名，确保无误后，点击</b><b style="font-size:150%; color:red" @click="findStaffInfo1(),show = true"><u>获取资格证信息</u></b></p>
					<p v-if="form.appNo==='APP02' && form.cityCode.includes('37') && !state.isIdCard && isShow" style="text-align:center"><b>核对资格证所属地市、姓名及身份证号，确保无误后，点击</b><b style="font-size:150%; color:red" @click="findStaffInfo1(),show = true"><u>获取资格证信息</u></b></p>
					<van-overlay :show="show" @click="show = false">
						 <div class="wrapper" @click.stop>
							<van-loading size="24px" color="#1989fa" vertical>
								信息查询中
							</van-loading>
						</div>
					</van-overlay>
					<div v-if="!isShow" class="section-top">
						<div v-for="(item,index) in listname" :key="index" id="wrap-box" class='wrap-box' @click="deny(index)" :class="choose == index ? 'addclass' : ''">
							<div class="circular"></div>
							<div>{{listname[index].text}}
								<b style="color:red" v-if ="listname[index].value === '01001'">(客运)</b>
								<b style="color:red" v-else-if ="listname[index].value === '02001'">(货运)</b>
								<b style="color:red" v-else>(危险品)</b>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="section-bottom">
				<van-button
					class="btn"
					native-type="submit"
					type="primary"
					block
					:loading="loading"
					loading-text="提交中..."
				>下一步
				</van-button>
			</div>
		</van-form>

		<!-- 驾校索引页 -->
		<van-popup
			v-model="showIndexBar"
			position="left"
			:style="{ width: '100%', height:'100%' }">
			<form action="javascript: return true;" style="padding: 0; margin: 0;">
				<van-search
					v-model="searchCorpValue"
					show-action
					placeholder="请输入搜索驾校关键词"
					@search="onCorpSearch"
					@cancel="onCorpSearchCancel"
					@clear="onCorpSearchClear"
				/>
			</form>
			<van-index-bar ref="indexBar">
				<template v-for="(item, index) in enterpriseIndexData">
					<van-index-anchor :index="index" :key="index">{{ index }}</van-index-anchor>
					<van-cell
						style="margin-top:0; box-shadow:0 0 0 0; border-radius:0;"
						:title="item1.corpName"
						@click="selectCorp(item1.indexChar, item1.corpId, item1.corpName)"
						v-for="(item1, index1) in item" :key="index1">
					</van-cell>
				</template>
			</van-index-bar>
		</van-popup>
	</div>
</template>

<script>
	import { Dialog } from 'vant';
	import {getSignupInfo, getSignupInfoWH,huiShiTongCheckStudent, getSignupInfoLN, getSignupInfoCZ, getLastYear, signupCzStudent, getEnterpriseIndexList,findStaffInfo,findBLevel} from "../../api/user";
	import { Overlay,Loading} from 'vant';
	import Vue from 'vue';
	export default {
		name: "stepTwo",
		data() {
			return {
				show: false,
				loading: false,
				step: 1,
				state: {
					showMakeUpStudy: false,
					showCity: true,
					isPhone: false,
					isIdCard: false,
				},
				form: {
					city: "",
					cityCode: "",
					studentName: "",
					certNum: "",
					mobile: "",
					appNo: "",
					isMakeUpStudy: "",
					trainType: "",
					enterpriseId: 0,
				},
				choose:-1,
				isShow: true,
				listname: [],
				mobilePattern: /^1[0-9]{10}$/,
				idCardPattern: /^(([1-9][0-9]{5}(19|20)[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}([0-9]|X|x))|([1-9][0-9]{5}[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}))|^([A-Z]\d{8}(\(\w{1}\))?)$/,
				agentId: '10',
				enterpriseIndexData: [],
				isSearched: false,
				searchCorpValue: '',
				indexChar: '',
				showIndexBar: false,
				corpName: '',
				trainYear: '',
			}
		},
		mounted() {
			Vue.use(Overlay);
			Vue.use(Loading);
			const appNo = sessionStorage.getItem('appno') || 'APP01';
			const loginInfor = JSON.parse(localStorage.getItem('loginInfor_' + appNo))
			this.form.appNo = appNo;
			// this.form.certNum = this.$route.query.idcard || ''
			this.form.certNum = loginInfor ? loginInfor.idcard : ''
			this.state.isIdCard = loginInfor && loginInfor.idcard ? true : false
			this.form.mobile = loginInfor ? loginInfor.phone : ''
			this.state.isPhone = loginInfor && loginInfor.phone ? true : false

			const form = JSON.parse(sessionStorage.getItem('form'));
			if (form) {
				this.form.city = this.$route.query.name || form.city || '';
				this.form.cityCode = this.$route.query.code || form.cityCode || '';
				this.form.studentName = form.studentName;
				// this.form.certNum = this.$route.query.idcard || form.certNum;
				this.form.certNum = loginInfor ? loginInfor.idcard : form.certNum;
				this.form.mobile = loginInfor ? loginInfor.phone : form.mobile;
			}
			//慧视通获取学员信息
			if (location.host.startsWith('hsy.')) {
				if (loginInfor) {
					this.huiShiTongCheckStudent(loginInfor.phone);
				}
			}

			// 乌海租户从接口中获取城市信息
			if (location.host.startsWith('wuhai.') || location.host.startsWith('antai.')) {
				//this.form.city = '乌海';
				//this.form.cityCode = '150300';
				//this.state.showCity = false;
				//this.state.isPhone = true;
				if (loginInfor) {
					this.getSignupInfoWH(loginInfor.idcard);
				}
				//if (appNo === 'APP02') {
				//	this.state.showMakeUpStudy = true;
				//}
			}
			// 双普租户默认指定城市
			if (location.host.startsWith('shuangpu.')) {
				this.$utils.dialog.alert('抱歉，暂时不能进入学习，恢复时间另行通知！')
				this.step--;
				this.$emit("changeStep", this.step);
				return;
				// if (appNo === 'APP02') {
				// 	this.state.showCity = false;
				// 	this.state.isPhone = true;
				// 	if (loginInfor) {
				// 		this.getSignupInfo(loginInfor.idcard);
				// 	}
				// }
				// if (appNo === 'APP03') {
				// 	this.form.city = '忻州';
				// 	this.form.cityCode = '140900';
				// }
			}
			// 辽宁继续教育租户默认指定城市
			if (sessionStorage.getItem('lnjxjy') === '1') {
				if (appNo === 'APP02') {
					this.state.showCity = false;
					this.state.isPhone = true;
					if (loginInfor) {
						this.getSignupInfoLN(loginInfor.idcard);
					}
				}
			}
			// 常州教练员继续教育默认信息
			if (appNo === 'APP02' && sessionStorage.getItem('czjxjy') === '1') {
				this.state.isPhone = true;
				if (loginInfor) {
					this.getSignupInfoCZ(loginInfor.idcard);
				}
			}
			// 安徽合肥计时
			if (appNo === 'APP01' && sessionStorage.getItem('anhuihefei') === '45') {
				if (loginInfor) {
					this.state.isPhone = true;
					this.state.isIdCard = false;
					this.form.studentName = loginInfor.studentName;
					this.form.city = '合肥市';
					this.form.cityCode = '340100';
				}
			}
			// 山东教练员
			if ((appNo === 'APP02' || appNo === 'APP04') && sessionStorage.getItem('anhuihefei') === '66') {
				if (loginInfor) {
					this.isShow = false;
					this.state.isPhone = true;
					this.state.isIdCard = false;
					this.form.studentName = loginInfor.studentName;
					this.form.city = '济南市';
					this.form.cityCode = '370100';
				}
			}
		},
		computed: {
			appno() {
				return this.$store.state.app.appno
			},
		},
		watch: {
			'form.trainType': {
				handler(newName) {
					if (this.form.cityCode === '320400' && this.form.appNo === 'APP02' && newName ==='001') {
						this.getLastYear();
					}
				},
				immediate: true
			}
		},
		methods: {
			findStaffInfo1(){
				if(this.state.isIdCard){
					this.form.certNum = JSON.parse(localStorage.getItem('loginInfor_' + sessionStorage.getItem('appno') || 'APP01')).idcard
				}
				findStaffInfo({
					"personnelName" : this.form.studentName,
					"idNumber": this.form.certNum,
					"cityCode":this.form.cityCode,
				}).then((res) => {
					res.qualifications.map( v => {
					// 	if (v.qualificationType === '99999') {
					// // this.listname.push('网络预约出租汽车驾驶员');
					// 	}
						if (v.qualificationType === '01001' && v.qualificationStatus == 1) {
							this.listname.push({text: '道路旅客运输驾驶员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
						}else if (v.qualificationType === '02001'&& v.qualificationStatus == 1) {
							this.listname.push({text: '道路货物运输驾驶员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
						}else if (v.qualificationType === '03001'&& v.qualificationStatus == 1) {
							this.listname.push({text: '危险货物运输驾驶员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
						}
						this.isShow = false;
						this.show = false;
					})
				}).catch(() => {
					this.isShow = false;
					this.show = false;
				});
			},
			findStaffInfo(){
				if(this.state.isIdCard){
					this.form.certNum = JSON.parse(localStorage.getItem('loginInfor_' + sessionStorage.getItem('appno') || 'APP01')).idcard
				}
				findBLevel({
					"personnelName" : this.form.studentName,
					"idNumber": this.form.certNum,
					"cityCode":this.form.cityCode,
				}).then((res)=>{
					if(res == undefined){
						this.$utils.dialog.alert('未查询到B级诚信考核记录，请咨询当地运政部门')
						this.isShow = false;
						this.show = false;
					}else{
						if(res.messageError == null || res.messageError == ''){
							findStaffInfo({
										"personnelName" : this.form.studentName,
										"idNumber": this.form.certNum,
										"cityCode":this.form.cityCode,
							}).then((res) => {
								res.qualifications.map( v => {
								// 	if (v.qualificationType === '99999') {
								// // this.listname.push('网络预约出租汽车驾驶员');
								// 	}
									if (v.qualificationType === '01001' && v.qualificationStatus == 1) {
										this.listname.push({text: '道路旅客运输驾驶员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									}else if (v.qualificationType === '02001'&& v.qualificationStatus == 1) {
										this.listname.push({text: '道路货物运输驾驶员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									}else if (v.qualificationType === '03001'&& v.qualificationStatus == 1) {
										this.listname.push({text: '危险货物运输驾驶员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									}
									// else if (v.qualificationType === '03002'&& v.qualificationStatus == 1) {
									// 	this.listname.push({text: '危险货物运输装卸管理人员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '03003'&& v.qualificationStatus == 1) {
									// 	this.listname.push({text: '危险货物运输押运人员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '03005'&& v.qualificationStatus == 1) {
									// 	this.listname.push({text: '剧毒化学品运输驾驶员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '03006'&& v.qualificationStatus == 1) {
									// 	this.listname.push({text: '剧毒化学品运输装卸管理人员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '03007'&& v.qualificationStatus == 1) {
									// 	this.listname.push({text: '剧毒化学品运输押运人员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '03009'&& v.qualificationStatus == 1) {
									// 	this.listname.push({text: '爆炸品运输驾驶员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '03010'&& v.qualificationStatus == 1) {
									// 	this.listname.push({text: '爆炸品运输装卸管理人员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '03011'&& v.qualificationStatus == 1) {
									// 	this.listname.push({text: '爆炸品运输押运人员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '03013'&& v.qualificationStatus == 1) {
									// 	this.listname.push({text: '放射性物品运输驾驶员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '03014'&& v.qualificationStatus == 1) {
									// 	this.listname.push({text: '放射性物品运输装卸管理人员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '03015') {
									// 	this.listname.push({text: '放射性物品运输押运人员', value: v.qualificationType, id: v.qualificationId, archiveNo: res.archiveNo, trainType: res.drivingClass, sex: res.gender});
									// }else if (v.qualificationType === '09001') {
									// 	// this.listname.push('巡游出租汽车驾驶员');
									// }
									this.isShow = false;
									this.show = false;
								})
							}).catch(() => {
								this.isShow = false;
								this.show = false;
							});
						}else{
							this.$utils.dialog.alert(res.messageError+",请咨询当地运政部门");
							this.isShow = false;
							this.show = false;
						}
					}
				})
			},
			deny(index) {
				this.choose = index;
				this.form.qualificationId = this.listname[index].id;
				this.form.qualificationType = this.listname[index].value;
				this.form.archiveNo = this.listname[index].archiveNo;
				this.form.sex = this.listname[index].sex;
				this.form.trainType = this.listname[index].trainType;
			},
			changeStep() {
				if (this.form.city && this.form.studentName && this.form.certNum) {
					this.toLogin();
				}
			},
			navToCity() {
				sessionStorage.setItem('form', JSON.stringify(this.form))
				this.$router.push({path: "/city", query: this.$route.query});
			},
			toLogin() {
				this.loading = true;
				this.$store.dispatch("Login", this.form).then(({data}) => {
					this.loading = false;
					if (sessionStorage.getItem('anhuihefei') === '45' || sessionStorage.getItem('anhuihefei') === '66') {
						sessionStorage.setItem('form', JSON.stringify(this.form))
						this.step++;
						this.$emit("changeStep", this.step);
						return;
					}
					if (data[0].studentPayStatus == 0 && this.$store.state.cache.screen.isIos && !this.$store.state.cache.screen.wx) {
						this.$utils.dialog.alert('身份验证失败，请询问平台管理员验证流程')
						return;
					} else {
						// 常州教练员继续教育再次报名
						if (sessionStorage.getItem('appno') === 'APP02' && sessionStorage.getItem('czjxjy') === '1') {
							this.signupCzStudent(this.form.enterpriseId);
						}
						sessionStorage.setItem('form', JSON.stringify(this.form))
						this.step++;
						this.$emit("changeStep", this.step);
					}
					Dialog.alert({
						title: '温馨提示',
						message: '身份验证已成功，下次可使用身份证号进行登录。\n请继续进行上传本人照片，完成后续操作。',
					}).then(() => {
						// on close
					});
					// studentPayStatus
					// this.$store
					// .dispatch("GetInfo")
					// .then(() => {
					// this.$router.push({ path: "/" });
					// })
					// .catch(err => {
					//   this.loading = false;
					//   this.$message.error("get user info error!!");
					// });
				}).catch(() => {
					this.loading = false;
					// this.$message.error(err);
				});
			},
			getSignupInfo(certNum) {
				getSignupInfo({
					"certNum": certNum
				}).then((res) => {
					if (res) {
						this.form.studentName = res.xm;
						this.form.mobile = res.sjhm;
						this.form.city = res.cityName;
						this.form.cityCode = res.cityCode;
					}
				}).catch(() => {
				});
			},
			huiShiTongCheckStudent(mobile){
				huiShiTongCheckStudent({
					"mobile": mobile
				}).then((res) => {
					if (res) {
						this.form.studentName = res.studentName;
						this.form.certNum = res.certNum;
						this.form.mobile = res.mobile;
						this.form.city = res.cityName;
						this.form.cityCode = res.cityCode;
					}
				}).catch(() => {
				});
			},
			getSignupInfoWH(certNum) {
				getSignupInfoWH({
					"certNum": certNum
				}).then((res) => {
					if (res) {
						this.form.studentName = res.xm;
						this.form.mobile = res.dh;
						if (res.fzjgdm === '150300') {
							this.form.city = '乌海市';
							this.form.cityCode = '150300';
						} else if (res.fzjgdm === '152900') {
							this.form.city = '阿拉善盟';
							this.form.cityCode = '152900';
						}
					}
				}).catch(() => {
				});
			},
			getSignupInfoLN(certNum) {
				getSignupInfoLN({
					"certNum": certNum
				}).then((res) => {
					if (res) {
						this.form.studentName = res.xm;
						this.form.mobile = res.sjhm;
						this.form.city = res.xzqhmc;
						this.form.cityCode = res.xzqhdm;
						this.form.trainType = res.dm;
					}
				}).catch(() => {
				});
			},
			getSignupInfoCZ() {
				getSignupInfoCZ({
					"token": this.$store.state.user.token
				}).then((res) => {
					if (res) {
						this.form.studentName = res.studentName;
						this.form.mobile = res.telephone;
						this.form.enterpriseId = res.enterpriseId;
						this.corpName = res.enterpriseName;
						this.form.city = '常州';
						this.form.cityCode = '320400';
						this.form.trainType = '001';
					}
				}).catch(() => {
				});
			},
			getLastYear() {
				getLastYear().then((res) => {
					if (res) {
						this.trainYear = res.year;
					}
				});
			},
			signupCzStudent(enterpriseId) {
				signupCzStudent({
					"token": this.$store.state.user.token,
					"enterpriseId": enterpriseId }).then(() => {});
			},
			mobileValidator(val) {
				if(val) {
					return this.mobilePattern.test(val);
				}
				return true;
			},
			searchEnterpriseList(cityCode, agentId, keyword) {
				getEnterpriseIndexList({
					cityCode: cityCode,
					agent: agentId,
					enterpriseName: keyword
				}).then((res) => {
					this.enterpriseIndexData = res;
				}).catch(() => {});
			},
			openIndexBar() {
				this.searchEnterpriseList(this.form.cityCode, this.agentId, '');
				this.showIndexBar = true;
				if(this.indexChar !== '') {
					this.$nextTick(()=>{
						this.$refs.indexBar.scrollTo(this.indexChar);
					})
				}
			},
			selectCorp(index, corpId, corpName) {
				this.indexChar = index;
				this.form.enterpriseId = corpId;
				this.corpName = corpName;
				this.showIndexBar = false;
			},
			onCorpSearch(val) {
				this.searchEnterpriseList(this.form.cityCode, this.agentId, val);
				this.isSearched = true;
			},
			onCorpSearchCancel() {
				if(this.isSearched){
					this.searchEnterpriseList(this.form.cityCode, this.agentId, '');
					this.isSearched = false;
				}
				this.showIndexBar = false;
			},
			onCorpSearchClear() {
				if(this.isSearched){
					this.searchEnterpriseList(this.form.cityCode, this.agentId, '');
					this.isSearched = false;
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.van-field__left-icon {
		margin-right: 8px;
	}

	.icon-img {
		width: 15px;
		vertical-align: text-bottom;
	}

	.wrap-box{
			width: 75%;
			// height: 80px;
			// margin: 30px auto;
			// border-radius: 10px;
			// padding: 20px 25px 20px 40px;
			padding-left: 43px;
			font-size: 19px;
			font-weight: 600;
			color: #2db1ac;
			// text-align: left;
			position: relative;
			.circular{
				width: 20px;
				height: 20px;
				border: 1px solid #c4b5b5;
				border-radius: 50%;
				position: absolute;
				top: 3px;
				left: 10px;
			}

		}
	.addclass .circular{
			background: #2db1ac;
		}

	.content-wrap {
		.van-cell {
			margin-top: 10px;
			// box-shadow: 0 0 8px 0px rgba(0,0,0,0.15);
			box-shadow: 0px 2px 8px 0px rgba(210, 210, 210, 0.48);
			border-radius: 8px;

			&::after {
				border: none;
			}

			.van-field__left-icon {
				margin-right: 8px;
			}
		}

		.van-field__left-icon {
			margin-right: 8px;
		}

		.section-top {
			padding: 20px 15px 18px;
			box-shadow: 0px 2px 8px 1px #e1e0df;
			border-radius: 14px;

			.title {
				position: relative;
				font-size: 17px;
				text-align: center;

				.txt {
					position: relative;
				}

				.line {
					position: absolute;
					top: 50%;
					display: inline-block;
					width: 30px;
					height: 2px;
					background: #fca142;
					margin: -1px 0 0;

					&:nth-of-type(1) {
						left: -42px;
					}

					&:nth-of-type(2) {
						right: -42px;
					}
				}
			}

			.form {
				margin-top: 18px;
			}
		}

		.section-bottom {
			margin-top: 40px;
		}
	}

	.van-search__content {
		.van-cell {
			margin-top: 0;
			box-shadow: 0 0 0 0;
			border-radius: 0;
		}
	}

	.APP02 {
		@colorMain: #2db1ac;
		@colorSecond: #48d8a0;

		.content-wrap {
			.section-top {
				.title {
					.line {
						background: @colorSecond;
					}
				}
			}
		}
	}

	.APP03 {
		@colorMain: #1175fe;
		@colorSecond: #3fadfa;

		.content-wrap {
			.section-top {
				.title {
					.line {
						background: @colorSecond;
					}
				}
			}
		}
	}
	 .wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.block {
		width: 120px;
		height: 120px;
		background-color: #fff;
	}
</style>
