<template>
	<div class="page-container">
		<nav-bar title="结业证查询" id="navbar"></nav-bar>
		<van-form @submit="onSearch" ref="formData" action="" v-if="!showResult">
			<van-field
				v-model="certNum"
				name="certNum"
				label="身份证号"
				placeholder="身份证号码"
				maxlength="18"
				:rules="[{pattern:/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,message:'请填写正确的身份证号码'}]"
			/>
			<div style="margin: 16px">
				<van-button round block type="info" native-type="submit">查询</van-button>
			</div>
		</van-form>
		<div v-if="showResult">
			<van-cell-group inset>
				<van-cell title="结业证数" :value="totalCount" />
			</van-cell-group>

			<van-cell-group :title="'课程：'+item.studyCourse" inset v-for="(item, index) of studyList" :key="index">
				<van-cell title="身份证号" :value="certNum" />
				<van-cell title="学习平台" :value="item.studyFirm" />
				<van-cell title="学员状态" v-if="item.studentState === 1">审核中，未完成学习</van-cell>
				<van-cell title="学员状态" v-if="item.studentState === 2">已推送平台，未完成学习</van-cell>
				<van-cell title="学员状态" v-if="item.studentState === 3">推送平台失败，未完成学习</van-cell>
				<van-cell title="学员状态" v-if="item.studentState === 5">学习完成</van-cell>
				<van-cell title="学员状态" v-if="item.studentState === 6">超期，未完成学习</van-cell>
				<van-cell title="下载结业证" is-link value="点击下载" v-if="item.studentState === 5" @click="getCertDownloadUrl(item.cqUrl)" style="color: #0064C8;" />
			</van-cell-group>

			<div style="margin: 16px">
				<van-button round block type="info" @click="goBack">返回</van-button>
			</div>
		</div>
		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#4994df">{{ title || '加载中...' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {NavBar, Toast} from "vant";
	import {getCoachCertificate, getStudyCertList, reloadCertDownloadUrl} from "../../api/user";
	export default {
		name: "coach-certificate-query",
		components: { NavBar },
		data() {
			return {
				studentName: '',
				certNum: '',
				studentId: 0,
				totalCount: 0,
				showResult: false,
				showLoading: false,
				title: null
			}
		},
		methods: {
			getCoachCertificate() {
				this.showLoading = true;
				getCoachCertificate({"certNum": this.certNum})
					.then((res) => {
						console.log(res)
						this.totalCount = res.length;
							this.studyList = res;
						if(res) {
							this.studentName = res.studentName;
							this.mobile = res.mobile;						
							this.showResult = true;
							console.log(studyList)
						} else {
							this.showLoading = false;
							Toast.fail("抱歉，未查询到学员信息");
						}
					})
					.catch(() => {
						this.showLoading = false;
					});
			},
			getStudyCertList() {
				getStudyCertList(this.studentId)
					.then((res) => {
						if(res) {
							this.studentName = res.studentName;
							this.certNum = res.certNum;
							this.cityCode = res.cityCode;
							this.totalCount = res.signUpRecord.length;
							this.studyList = res.signUpRecord;
							this.showResult = true;
						}
						this.showLoading = false;
					})
					.catch(() => {
						this.showLoading = false;
					});
			},
			getCertDownloadUrl(obj) {
				window.location.href = obj;
			},
			reloadCertDownloadUrl() {
				this.showLoading = true;
				reloadCertDownloadUrl(obj.planId, obj.certificateId).then((res) => {
					this.showLoading = false;
					if(res) {
						window.location.href = res.url;
					}
				}).catch(() => {
					this.showLoading = false;
				});
			},
			onSearch() {
				if(this.certNum === '') {
					Toast.fail("请您输入身份证号码");
					return;
				}
				this.getCoachCertificate();
			},
			download(obj) {
				window.location.href = "/renren-fast/app/exp/download/" + obj.planId + "/" + obj.certificateId;
			},
			goBack() {
				this.showResult = false;
			}
		}
	}
</script>

<style scoped>
	#navbar {
		background-color: #409eff;
	}
	#navbar /deep/ .van-ellipsis {
		color: #fff !important;
	}
	#navbar /deep/ .van-nav-bar__text {
		color: #fff !important;
	}
	.van-cell-group__title {
		background-color: #f5f5f5;
	}
</style>
