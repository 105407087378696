<template>
	<div class="login_layout">
		<div class="main">
			<div class="main_portrait">
				<div class="box bg_primary">
					<!-- <img style="max-width:100%" src="https://h5.xunmeizaixian.com/picture/logo_jishi.gif" alt="" /> -->
					<img style="max-width:100%" src="../../../assets/shandong/jiaxie.jpg" alt=""/>
				</div>
			</div>
			<div class="main_body">
				<h1 class="title">您好，请登录</h1>
				<div class="form">
					<van-field v-model="param.idCard" :clearable="true" placeholder="请输入身份证号" maxlength="18">
						<div class="slot-icon" slot="left-icon">
							<img src="@/assets/icon-idcard.png" alt="idCard"/>
						</div>
					</van-field>
					<van-field v-model="param.password" type="password" :clearable="true" placeholder="默认密码为123456">
						<div class="slot-icon" slot="left-icon">
							<img src="@/assets/icon-password.png" alt="password"/>
						</div>
					</van-field>
					<div class="other" style="display: none">
						<button class="la-btn plain round">首次登录</button>
					</div>
					<button class="btn" @click="clickLogin">登录</button>
					<p class="explain">
						<span class="radio border" @click="isReadAgree = !isReadAgree">
							<transition name="fade">
								<span v-show="isReadAgree" class="bg_primary"></span>
							</transition>
						</span>
						<span class="text">我已阅读并同意
							<span class="text_primary" @click="$router.push('/service-agreement')">《服务协议》</span>及
							<span class="text_primary" @click="$router.push('/privacy-policy')">《隐私协议》</span>
						</span>
					</p>
				</div>
			</div>
		</div>
		<!-- <div class="other_login" style="display: none;" ref="otherLogin">
			<div class="divider">
				<div class="line left"></div>
				<div class="text">其它登录方式</div>
				<div class="line right"></div>
			</div>
			<div class="login_option">
				<div class="phone_login" @click="$emit('toggleLogin', 'phoneLogin')">
					<img src="@/assets/icon-phone-login.png" alt="">
					<p>手机号登录</p>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
import Validation from '@/utils/validation'
import {wxAuth, coachLogin, tonganCreateSign} from '@/api/user'
import {getLoginIdCard, setLoginIdCard, getLoginPwd, setLoginPwd} from "@/utils/cookie";

export default {
	name: 'idcardLogin',
	data: () => ({
		isReadAgree: false, // 是否同意协议
		param: {
			idCard: '',
			password: ''
		},
		rules: {
			idCard: [
				{required: true, message: '身份证号不能为空'},
				{min: 8, max: 18, message: '身份证号码位数输入有误，请重新输入'}
			],
			password: [{required: true, message: '密码不能为空'}]
		},
	}),
	created() {

	},
	mounted() {
		if (typeof(getLoginIdCard()) !== 'undefined') {
			this.param.idCard = getLoginIdCard();
		}
		if (typeof(getLoginPwd()) !== 'undefined') {
			this.param.password = getLoginPwd();
		}
	},
	methods: {
		loginVertify() {
			if (!this.isReadAgree) {
				this.$utils.dialog.alert('您需要同意服务协议及隐私策略才能操作。')
				return false
			}
			const message = new Validation(this.rules, this.param).start()
			if (message) {
				this.$utils.dialog.alert(message)
				return false
			}
			return true
		},
		clickLogin() {
			if (this.loginVertify()) {
				// if (location.host.startsWith('shandong.')) {
				// 	// if (location.host.startsWith('localhost:')) {
				// 	shanDongByIdCard(this.param).then(res => {
				// 		if (res.code === 0 || res.code === 200) {
				// 			const appNo = sessionStorage.getItem('appno') || 'APP02';
				// 			// localStorage.setItem('loginInfor_' + appNo, JSON.stringify({
				// 			// 	idcard: this.param.idCard,
				// 			// 	token: res.data.h5token
				// 			// }));
				// 			localStorage.removeItem('loginInfor_' + appNo);
				// 			setLoginIdCard(this.param.idCard);
				// 			setLoginPwd(this.param.password);
				// 			getStudentStatus({certNum: this.param.idCard}).then(data => {
				// 				localStorage.setItem('idCard', this.param.idCard)
				// 				if (data.state === 0) {
				// 					this.$router.push({path: '/shandong', query: {appno: appNo}})
				// 				} else if (data.state === 1) {
				// 					if (data.trainingOrgName === '大连枫升科技有限公司') {
				// 						window.location.href = this.HJurl + data.idCard + this.HJqualificationType + data.qualificationType;
				// 					} else {
				// 						window.location.href = this.XMurl + data.idCard + this.XMappNo;
				// 					}
				// 				} else if (data.state === 99) {
				// 					this.$dialog.alert({
				// 						message: '课程已学习完毕，是否报名新的课程',
				// 						theme: 'round-button',
				// 						showCancelButton: true,
				// 						confirmButtonText: '报名新课程',
				// 						cancelButtonText: '继续学习',
				// 						confirmButtonColor: '#fca142',
				// 						width: 290,
				// 					}).then(() => {
				// 						this.$router.push({path: '/shandong', query: {appno: appNo}})
				// 					})
				// 						.catch(() => {
				// 							if (data.trainingOrgName === '大连枫升科技有限公司') {
				// 								window.location.href = this.HJurl + data.idCard + this.HJqualificationType + data.qualificationType;
				// 							} else {
				// 								window.location.href = this.XMurl + data.idCard + this.XMappNo;
				// 							}
				// 						});
				// 				}
				// 			}).catch(() => {
				// 			})
				// 		}
				// 	})
				//} else {
					coachLogin(this.param).then(res => {
						console.log("返回结果",res)
						setLoginIdCard(this.param.idCard);
						setLoginPwd(this.param.password);
						if (res.studyFirm === '北京训美') {
							if (res.studyCourse === '教练员岗前教育') {
								window.location.href = "https://h5.xunmeizaixian.com/index.html?/#/train?state=single&appNo=APP04&organizationNo=S4D55C32OA5&idcard=" + res.certNum + "&name=" + res.studentName
							} else {
								window.location.href = "https://h5.xunmeizaixian.com/index.html?/#/train?state=single&appNo=APP02&organizationNo=S4D55C32OA5&idcard=" + res.certNum + "&name=" + res.studentName
							}
							return;
						} else if (res.studyFirm === '北京通安') {
							tonganCreateSign(this.param.idCard).then(response => {
								window.location.href = 'https://mb.anjia365.com/c/index.html?_ver=2&_t=4&tid=98&ts=' + response.ts + '&username=' + this.param.idCard + '&sign=' + response.sign;
							})
							return;
						}
						if (res.code === 0 || res.code === 200) {

							// sessionStorage.setItem("token", res.data.h5token);
							// this.$router.push({ path: '/auth', query: { idcard: this.param.idCard, token: res.data.h5token }})
							const appNo = sessionStorage.getItem('appno') || 'APP01';
							localStorage.setItem('loginInfor_' + appNo, JSON.stringify({
								idcard: this.param.idCard,
								token: res.data.h5token
							}));
							
							if (this.$store.state.cache.screen.wx) {
								this.wxAuthPay()
							} else {
								this.$router.push({
									path: '/auth',
									query: {idcard: this.param.idCard, token: res.data.h5token}
								})
							}
						}
					})
				//}
			}
		},
		// 微信授权
		wxAuthPay() {
			let href = window.location.href.replace('login', 'auth')
			wxAuth('', href).then(({url}) => {
				window.location.href = url
			})
		},
	}
}
</script>

<style scoped>
.main_portrait .box {
	overflow: hidden
}
.slot-icon {
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.slot-icon img {
	width: 20px;
	height: 20px;
}
</style>
