<template>
	<div class="choose-page">
		<div class="header" @click="tool(dian)">
			<van-nav-bar :title="pageTitle" @click-left="navBack" right-text="退出登录" @click-right="logout" left-arrow
						 :border="false" v-if="showLogout"></van-nav-bar>
			<van-nav-bar :title="pageTitle" @click-left="navBack" left-arrow
						 :border="false" v-if="!showLogout"></van-nav-bar>
		</div>
		<!-- VIP区域 -->
		<div class="content" v-if="tradename.length > 0">
			<van-tabs
				v-if="issubject === undefined"
				v-model="activeSubject"
				animated
				color="#2db1ac"
				title-active-color="#2db1ac"
				title-inactive-color="#666"
				line-height="5"
				@click="onClickTab"
			>
				<van-tab
					v-for="(item, index) in subjects"
					:key="index"
					:title="item.label"
					:name="item.name"
				>
					<div class="content-main" style="padding-bottom: 25px">
						<van-grid :border="false" :column-num="3">
							<van-grid-item v-for="(item,index) in picture" :key="index" class="main-grid"
										   @click="clickpicturesV2(index)">
								<div class="grid-wrap">
									<div class="main-img">
										<van-badge :content="item.isFree ? '免费' : 'VIP'">
											<img :src="item.url" :alt="item.tip">
										</van-badge>
									</div>
								</div>
							</van-grid-item>
						</van-grid>
					</div>
					<div class="content-bottom" style="margin-top: 25px">
						<van-badge content="VIP">
						<img :src="startImage.url" :alt="startImage.tip" style="width: 100%"
							 @click="clickpictures(2, 'start')">
						</van-badge>
					</div>
				</van-tab>
			</van-tabs>
		</div>
		<!-- 免费区域 -->
		<div class="content" v-if="tradename.length === 0">
			<van-tabs
				v-if="issubject === undefined"
				v-model="activeSubject"
				animated
				color="#2db1ac"
				title-active-color="#2db1ac"
				title-inactive-color="#666"
				line-height="5"
				@click="onClickTab"
			>
				<van-tab
					v-for="(item, index) in subjects"
					:key="index"
					:title="item.label"
					:name="item.name"
				>
					<div class="content-main" style="padding-bottom: 25px">
						<van-grid :border="false" :column-num="3">
							<van-grid-item v-for="(item,index) in pictureFree" :key="index" class="main-grid"
										   @click="clickpicturesFree(index)">
								<div class="grid-wrap">
									<div class="main-img">
										<img :src="item.url" :alt="item.tip">
									</div>
								</div>
							</van-grid-item>
						</van-grid>
					</div>
				</van-tab>
			</van-tabs>
			<div class="content-main" style="padding-bottom: 25px" v-if="issubject !== undefined">
				<van-grid :border="false" :column-num="3">
					<van-grid-item v-for="(item,index) in pictureFree" :key="index" class="main-grid"
								   @click="clickpicturesFree(index)">
						<div class="grid-wrap">
							<div class="main-img">
								<img :src="item.url" :alt="item.tip">
							</div>
						</div>
					</van-grid-item>
				</van-grid>
			</div>
		</div>
	</div>
</template>

<script>
import {getExercisesId, getProduct} from "@/api/user";
import Vconsole from 'vconsole'
export default {
	name: "vipchoose",
	data() {
		return {
			dian: 0,
			pageTitle: '机动车驾驶培训C1/C2/C3题库',
			showLogout: false,
			tradename: [],
			activeSubject: Number(sessionStorage.getItem('activeSubject'))?Number(sessionStorage.getItem('activeSubject')):0,
			subject: Number(sessionStorage.getItem('subject'))?Number(sessionStorage.getItem('subject')):1,
			subjects: [
				{
					label: "科目一",
					value: 1,
				},
				{
					label: "科目四",
					value: 4,
				}
			],
			picture: [
				{
					url: require('@/assets/vip2/cx_icon_03.png'),
					text: '随机练习',
					tip: '随机练习',
					origin: '随机练习',
					isFree: true
				},
				{
					url: require('@/assets/vip2/cx_icon_05.png'),
					text: '顺序练习',
					tip: '顺序练习',
					origin: '顺序练习',
					isFree: true
				},
				{
					url: require('@/assets/vip2/cx_icon_06.png'),
					text: '模拟考试',
					tip: '模拟考试',
					origin: '模拟考试',
					isFree: true
				},
				{
					url: require('@/assets/vip2/cx_icon_01.png'),
					text: 'VIP课程',
					tip: 'VIP课程',
					origin: '精髓讲解',
					isFree: false
				},
				{
					url: require('@/assets/vip2/cx_icon_02.png'),
					text: '考前密卷',
					tip: '考前密卷',
					origin: '考前密卷',
					isFree: false
				},
				{
					url: require('@/assets/vip2/cx_icon_04.png'),
					text: '精选题',
					tip: '精选题',
					origin: '精选试题',
					isFree: false
				}
			],
			pictureFree: [
				{
					url: require('@/assets/vip2/cx_icon_03.png'),
					text: '随机练习',
					tip: '随机练习',
					origin: '随机练习'
				},
				{
					url: require('@/assets/vip2/cx_icon_05.png'),
					text: '顺序练习',
					tip: '顺序练习',
					origin: '顺序练习'
				},
				{
					url: require('@/assets/vip2/cx_icon_06.png'),
					text: '模拟考试',
					tip: '模拟考试',
					origin: '模拟考试'
				}
			],
			startImage: {
				url: require('@/assets/vip2/cx_start_01.png'),
				text: '开始考试',
				tip: '真实考场模拟',
				origin: '真实考场'
			}
		}
	},
	components: {},
	computed: {
		issubject() {
			return this.$route.query.subject
		},
		categoryId() {
			return this.$route.query.categoryId
		},
		token() {
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')) === null? '': JSON.parse(localStorage.getItem('loginInTKfor_APP02')).token
		},
		organizationNo() {
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')) === null? '': JSON.parse(localStorage.getItem('loginInTKfor_APP02')).organizationNo;
		},
		productId(){
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')) === null? '': JSON.parse(localStorage.getItem('loginInTKfor_APP02')).productId;
		},
		appNo() {
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')) === null? '': sessionStorage.getItem('appno') || 'APP02'
		},
	},
	created() {
		// h5获取商品名称
		this.getProduct();
		if (localStorage.getItem('loginInTKfor_APP02') != null) {
			this.showLogout = true;
		}
		this.pageTitle = this.setPageTitle(this.categoryId);
		if (this.organizationNo === 'C24J6X01T0K') {
			this.showLogout = false;
		}
	},
	methods: {
		tool(dian) {
			this.dian = dian+1
			if (this.dian >= 5) {
				new Vconsole();
			}
		},
		// 切换科目
		onClickTab(index, title) {
			console.log("当前坐标",index, title);
			sessionStorage.setItem("activeSubject",index);
			if (index === 0) {
				this.subject = 1;
				this.activeSubject = index;
			} else {
				this.subject = 4;
				this.activeSubject = index;

			}
			sessionStorage.setItem("subject",this.subject);
			//h5获取商品名称
			this.getProduct();
			window.location.reload();
		},
		// h5获取商品名称
		getProduct() {
			getProduct(
				this.categoryId,
				this.issubject ? this.issubject : this.subject
			).then((res) => {
				if(this.organizationNo == 'C24J6X01T0K'){
					res.forEach(element => {
						this.productId.split(',').forEach((item, index) => {
							if(element.id == item){
								console.log(index,item)
								this.tradename.push(element)
							}
						});
					});
				}else{
					this.tradename = res;
				}	
				})
				.catch(() => {
			})
		},
		// 去购买套餐页
		tobuy() {
			this.$dialog.alert({
				message: "请先去购买当前套餐",
				theme: "round-button",
				confirmButtonColor: "#2db1ac",
				width: 290,
			}).then(() => {
				if (this.organizationNo === 'C26A8CH10NC') {
					// 南昌代理商购买链接
					top.location.href = 'https://app.jxhkc.com/app/index.php?i=5&c=entry&m=baduyu_shop&do=mobile&r=diypage&id=167';
				} else if (sessionStorage.getItem('organizationNo') === 'IHHdSNsdNDE') {
       				top.location.href = 'http://wechat.1d1d1d1d.com/xunmei/theoryToPay?token=' + sessionStorage.getItem('token');
      			} else {
					this.$router.push({
						path: '/viplist',
						query: {subject: this.subject, categoryId: this.categoryId, appNo: this.appNo}
					})
				}
			});
		},
		clickpicturesV2(index) {
			if (index === 0) {
				// 随机练习
				this.pattern(2, '随机练习');
			} else if (index === 1) {
				// 顺序练习
				this.pattern(1, '顺序练习');
			} else if (index === 2) {
				// 模拟考试
				this.pattern(3, '模拟考试');
			} else if (index === 3) {
				// VIP课程
				this.clickpictures(3, '');
			} else if (index === 4) {
				// 考前密卷
				this.clickpictures(4, '');
			} else if (index === 5) {
				// 精选500题
				this.clickpictures(5, '');
			} else {
				this.$dialog.alert({
					message: "当前VIP权益正在建设中",
					theme: "round-button",
					confirmButtonColor: "#2db1ac",
					width: 290,
				});
			}
		},
		clickpicturesFree(index) {
			if (index === 0) {
				this.pattern(2, '随机练习');
			} else if (index === 1) {
				this.pattern(1, '顺序练习');
			} else if (index === 2) {
				this.pattern(3, '模拟考试');
			} else {
				this.$dialog.alert({
					message: "当前VIP权益正在建设中",
					theme: "round-button",
					confirmButtonColor: "#2db1ac",
					width: 290,
				});
			}
		},
		clickpictures(index, action) {
			let productList = [];
			if (action === 'start') {
				// 真实考场单独放置
				productList.push({});
				productList.push({});
				productList.push(this.startImage);
			} else {
				productList = this.picture;
			}
			this.tradename.map(v => {
				if (v.alias === productList[index].origin) {
					if (v.status === 0) {
						this.$dialog.alert({
							message: "当前VIP权益正在建设中",
							theme: "round-button",
							confirmButtonColor: "#2db1ac",
							width: 290,
						});
					} else {
						if (localStorage.getItem('loginInTKfor_APP02') == null) {
							this.$router.push({path: '/viplogin', query: {appNo: 'APP02'}})
							return;
						}
						if (index === 3) {
							// 精髓讲解-VIP课程
							if (JSON.parse(localStorage.getItem('loginInTKfor_APP02')) !== null && JSON.parse(localStorage.getItem('loginInTKfor_APP02')).productId && JSON.parse(localStorage.getItem('loginInTKfor_APP02')).productId.indexOf((v.id).toString()) !== -1) {
								this.$router.push({
									path: '/vipquintessence',
									query: {subject: this.subject, categoryId: this.categoryId, appNo: this.appNo}
								})
							} else {
								this.tobuy();
							}
						} else if (index === 5) {
							// 精选试题-精选500题
							if (JSON.parse(localStorage.getItem('loginInTKfor_APP02')) !== null && JSON.parse(localStorage.getItem('loginInTKfor_APP02')).productId && JSON.parse(localStorage.getItem('loginInTKfor_APP02')).productId.indexOf((v.id).toString()) !== -1) {
								this.$router.push({
									path: '/vipexam',
									query: {subject: this.subject, categoryId: this.categoryId, appNo: this.appNo}
								})
							} else {
								this.tobuy();
							}
						} else if (index === 2) {
							// 真实考场
							if (JSON.parse(localStorage.getItem('loginInTKfor_APP02')) !== null && JSON.parse(localStorage.getItem('loginInTKfor_APP02')).productId && JSON.parse(localStorage.getItem('loginInTKfor_APP02')).productId.indexOf((v.id).toString()) !== -1) {
								this.$router.push({
									path: '/vipsimulation',
									query: {subject: this.subject, categoryId: this.categoryId, appNo: this.appNo}
								})
							} else {
								this.tobuy();
							}
						} else if (index === 4) {
							// 考前密卷
							if (JSON.parse(localStorage.getItem('loginInTKfor_APP02')) !== null && JSON.parse(localStorage.getItem('loginInTKfor_APP02')).productId && JSON.parse(localStorage.getItem('loginInTKfor_APP02')).productId.indexOf((v.id).toString()) !== -1) {
								this.$router.push({
									path: '/vipsecret',
									query: {subject: this.subject, categoryId: this.categoryId, appNo: this.appNo}
								})
							} else {
								this.tobuy();
							}
						}
					}
				}
			})
		},
		// 随机练习、顺序练习、模拟考试
		pattern(val, title) {
			getExercisesId({
				categoryId: Number(this.categoryId),
				subject: this.issubject ? Number(this.issubject) : this.subject,
				practiceWay: val
			}).then((data) => {
				if (data) {
					if (data.ExercisesId.length > 0) {
						sessionStorage.setItem('exam', JSON.stringify(data))
						this.$router.push({
							path: '/viporder',
							query: {title,}
						})
					} else {
						this.$dialog.alert({
							message: "题库正在建设中，敬请期待",
							theme: "round-button",
							width: 290,
						});
					}
				} else {
					this.$dialog.alert({
						message: "题库正在建设中，敬请期待",
						theme: "round-button",
						width: 290,
					});
				}
			}).catch(() => {
			})
		},
		selectCorp(index, corpId, corpName) {
				this.indexChar = index;
				this.form.enterpriseId = corpId;
				this.corpName = corpName;
				this.showIndexBar = false;
			},
		// 点击回退
		navBack() {
			if (this.organizationNo === 'C24J6X01T0K') {
				console.log("安卓",this.$store.state.cache.screen)
				console.log("ios",this.$store.state.cache.screen.isIos)
				// 兼容广东交信投使用原生开发的app,app定义closeWindows()方法
				if (this.$store.state.cache.screen.isAndroid) {
					console.log("调用安卓方法Android_JS",window)
					if (!window.Android_JS) {
						this.$utils.dialog.alert("安卓方法Android_JS不存在");
						return;
					}
					if (!window.Android_JS.closeWindows) {
						this.$utils.dialog.alert("安卓方法closeWindows不存在");
						return;
					}
					window.Android_JS.closeWindows();
				}
				if (this.$store.state.cache.screen.isIos) {
					console.log("调用ios方法")
					window.webkit.messageHandlers.closeWindows.postMessage(null);
				}
			}else{
				this.$router.go(-1);
			}
		},
		// 退出登录
		logout() {
			// 清除缓存
			sessionStorage.clear();
			localStorage.clear();
			this.$router.push({path: '/viphome', query: {appNo: 'APP02'}})
		},
		// 页面标题设置
		setPageTitle(categoryId) {
			let pageTitle = '题库';
			switch (categoryId) {
				case "102":
					pageTitle = '机动车驾驶培训C1/C2/C3题库';
					break;
				case "105":
					pageTitle = '机动车驾驶培训A2/B2题库';
					break;
				case "104":
					pageTitle = '机动车驾驶培训A1/B1/A3题库';
					break;
				case "106":
					pageTitle = '机动车驾驶培训D/E/F题库';
					break;
				case "109":
					pageTitle = '客运资格证考题题库';
					break;
				case "110":
					pageTitle = '货运资格证考题题库';
					break;
				case "111":
					pageTitle = '出租车资格证考题题库';
					break;
				case "112":
					pageTitle = '教练员资格证考题题库';
					break;
				case "113":
					pageTitle = '危险品资格证考题题库';
					break;
				case "114":
					pageTitle = '网约车资格证考题题库';
					break;
				case "116":
					pageTitle = '客运继续教育题库';
					break;
				case "117":
					pageTitle = '货运继续教育题库';
					break;
				case "118":
					pageTitle = '出租车继续教育题库';
					break;
				case "119":
					pageTitle = '教练员继续教育题库';
					break;
				case "120":
					pageTitle = '危险品继续教育题库';
					break;
				case "122":
					pageTitle = '驾驶员培训题库';
					break;
				case "123":
					pageTitle = '客运企业培训题库';
					break;
				case "124":
					pageTitle = '货运企业培训题库';
					break;
				case "125":
					pageTitle = '危险品企业培训题库';
					break;
				case "126":
					pageTitle = '管理人员培训题库';
					break;
				case "127":
					pageTitle = '两类人员培训题库';
					break;
			}
			return pageTitle;
		}
	}
}
</script>

<style lang="less" scoped>
.choose-page {
	min-height: 100vh;
	background: #f5f5f5;
}
.van-nav-bar ::v-deep .van-nav-bar__title {
  max-width: 80%!important;
}
.van-nav-bar {
	background-color: #2db1ac !important;
}

::v-deep .van-icon {
	color: #fff;
}

::v-deep .van-ellipsis {
	color: #fff;
}

::v-deep .van-nav-bar__text {
	color: #fff;
}

::v-deep .van-badge--fixed {
	right: 12px;
}

.header {
	width: 100%;
	height: 200px;
	padding-bottom: 14px;
	border-bottom-left-radius: 26px 10px;
	border-bottom-right-radius: 26px 10px;
	background: linear-gradient(90deg, #2db1ac, #2db1ac)
}

.content {
	margin: -130px 15px 15px;
	padding: 20px 15px 15px;
	background: #fff;
	border-radius: 15px;
	box-shadow: 2px 2px 8px 1px rgb(46 176 172);

	.content-main {
		width: 100%;
		height: 100%;
		background: url('../../assets/vip/000.png') no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		padding: 15px 0;

		.main-grid {
			margin: -12px 0;

			/deep/ .van-grid-item__content {
				background-color: transparent;
			}

			position: relative;

			.grid-wrap {
				animation: myfirst 2s;
				-webkit-animation: myfirst 2s;
				animation-iteration-count: 99999;
				-webkit-animation-iteration-count: 99999;

				.main-img {
					width: 100%;
					text-align: center;
					vertical-align: middle;

					img {
						vertical-align: middle;
						width: 100%;
					}
				}

				.main-text {
					position: absolute;
					left: 0;
					bottom: 15%;
					font-size: 17px;
					font-weight: 700;
					width: 100%;
					text-align: center;
				}
			}

			@keyframes myfirst {
				0% {
					transform: scale(1);
				}
				50% {
					transform: scale(.96);
				}
				100% {
					transform: scale(1);
				}
			}
			@-webkit-keyframes myfirst {
				0% {
					transform: scale(1);
				}
				50% {
					transform: scale(.96);
				}
				100% {
					transform: scale(1);
				}
			}
		}

		.content-bottom {
			margin-top: 50px;

			.cen-bot {
				margin: 20px auto;
				width: 60%;
				height: 35px;
				background: linear-gradient(to right, #48d8a0, #2db1ac);
				border-radius: 10px;
				color: #fff;
				font-size: 18px;
				font-weight: 700;
				text-align: center;
				line-height: 35px;
				box-shadow: 2px 2px 5px #616060;
			}
		}
	}

	.main-center {
		width: 100%;
		height: 100%;
		background: url('../../assets/vip/000.png') no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		padding: 15px 0;

		.cen-pat {
			margin: 30px auto 40px;
			width: 80%;
			height: 60px;
			background: linear-gradient(to right, #48d8a0, #2db1ac);
			color: #fff;
			border-radius: 10px;
			box-shadow: -2px 3px 10px #17605e;
			text-align: center;
			font-size: 20px;
			font-weight: 700;

			span {
				line-height: 60px;
			}
		}
	}
}
</style>
