<template>
	<div class="page-container">
		<nav-bar title="结业证书查询" :fixed="true" id="navbar"></nav-bar>
		<!-- 报名表单 -->
		<div class="form-area" v-if="!isSignupSuccess && !isSignupConfirm">
			<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
				请您第一步输入身份证号码后点击[查询]按钮获取结业证书信息！
			</van-notice-bar>

			<van-form @submit="onConfirm" ref="formData" action="">
				<van-field
					v-model="form.certNum"
					type="input"
					name="certNum"
					center
					clearable
					label="身份证号"
					placeholder="请输入身份证号码"
					maxlength="18"
					:rules="[{pattern:/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,message:'请填写正确的身份证号码'}]"
				>
					<template #button>
						<van-button
							size="small"
							type="primary"
							@click="getStudentInfo"
							native-type="button"
							v-if="canBeSearch"
              :rules="[{pattern:/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,message:'请填写正确的身份证号码'}]"
						>查询
						</van-button>
					</template>
				</van-field>
				<div v-if="searchResult">
					<!-- 查询资格证后出现 -->
					<div class="title" v-if="bookCount > 0">
						<span style="color: #E10900;">您共有<strong>{{bookCount}}</strong>张资格证，请选择报名用的资格证！</span>
					</div>
					<!-- 资格证列表 -->
					<van-field
						readonly
						clickable
						name="cyzglb"
						v-model="form.cyzglb"
						label="选择资格证"
						placeholder="点击选择资格证"
						@click="showPicker = true"
						v-if="canBeSearch"
					/>
					<van-popup v-model="showPicker" position="bottom">
						<van-picker
							show-toolbar
							:columns="columns"
							@confirm="onConfirmPicker"
							@cancel="showPicker = false"
							value-key="text"
						/>
					</van-popup>
					<!-- 资格证号码 -->
					<van-field
						v-model="form.cyzgzh"
						name="zgzh"
						label="资格证号"
						placeholder="资格证号码"
						v-if="canBeSearch"
					/>
					<!-- 发证机构 -->
					<van-field
						v-model="form.fzjgmc"
						name="fzjgmc"
						label="发证机构"
						placeholder="发证机构"
						v-if="canBeSearch"
					/>
					<!-- 性别 -->
					<van-field name="xb" label="性别" v-if="canBeSearch">
						<template #input>
							<van-radio-group v-model="form.xb" direction="horizontal">
								<van-radio name="男">男</van-radio>
								<van-radio name="女">女</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<!-- 手机号码 -->
					<van-field
						v-model="form.dh"
						name="dh"
						type="tel"
						label="手机号码"
						placeholder="手机号码"
						maxlength="11"
					/>
					<!-- 出生日期 -->
					<van-field
						v-model="form.csrq"
						name="csrq"
						label="出生日期"
						placeholder="出生日期"
						v-if="canBeSearch"
					/>
					<!-- 住址 -->
					<van-field
						v-model="form.zz"
						name="zz"
						label="住址"
						placeholder="住址"
						v-if="canBeSearch"
					/>
					<!-- 准驾车型 -->
					<van-field
						v-model="form.jszzjcx"
						name="jszzjcx"
						label="准驾车型"
						placeholder="准驾车型"
					/>
					<div class="title">
						<span style="color: #E10900;">请您如实填写以下报名信息</span>
					</div>
					<van-field
						v-model="fieldValue"
						name="fieldValue"
						is-link
						readonly
						label="可选课程"
						placeholder="请选择报名培训课程"
						@click="show = true"
					/>
					<van-popup v-model="show" round position="bottom">
						<van-cascader
							v-model="cascaderValue"
							title="请选择报名培训课程"
							:options="options"
							@close="show = false"
							@finish="onFinish"
						/>
					</van-popup>
					<van-cell
						title="课程名称"
						:value="planName"
						label="请您仔细确认课程名称无误"
					/>
					<!-- 培训年度 -->
					<van-field
						readonly
						clickable
						name="pxnd"
						v-model="form.pxnd"
						label="年度选择"
						placeholder="点击选择培训年度"
						@click="showPickerNd = true"
					/>
					<van-popup v-model="showPickerNd" position="bottom">
						<van-picker
							show-toolbar
							:columns="columnsNd"
							@confirm="onConfirmPickerNd"
							@cancel="showPickerNd = false"
							value-key="text"
							:default-index="0"
						/>
					</van-popup>
					<!-- 审验地区 -->
					<van-field
						readonly
						clickable
						name="sydq"
						v-model="form.sydq"
						label="审验地区"
						placeholder="点击选择审验地区"
						@click="showPickerSy = true"
					/>
					<van-popup v-model="showPickerSy" position="bottom">
						<van-picker
							show-toolbar
							:columns="columnsSy"
							@confirm="onConfirmPickerSy"
							@cancel="showPickerSy = false"
							value-key="text"
							:default-index="0"
						/>
					</van-popup>
					<!-- 学员报名照片 -->
					<div style="margin-top: 10px;">
						<div style="text-align: center">
							<van-uploader v-model="photoList" :after-read="afterRead" :max-count="1" />
						</div>
						<div style="text-align: center">学员照片上传，五官清晰可见</div>
					</div>
					<div style="margin: 16px">
						<van-button round block type="info" native-type="submit">下一步
							
						</van-button>
					</div>
				</div>
			</van-form>
		</div>

		<!-- 报名信息确认 -->
		<div class="confirm-area" v-if="isSignupConfirm">
			<div class="checked-plan">
				<van-cell-group title="请确认您的报名信息" inset>
					<van-cell title="真实姓名" :value="form.studentName" />
					<van-cell title="身份证号" :value="form.certNum" />
					<van-cell title="性别" :value="form.xb" v-if="form.xb" />
					<van-cell title="手机号码" :value="form.dh" />
					<van-cell title="资格证类别" :value="form.cyzglb" v-if="form.cyzglb" />
					<van-cell title="资格证号" :value="form.cyzgzh" v-if="form.cyzgzh" />
					<van-cell title="准驾车型" :value="form.jszzjcx" />
					<van-cell title="报名课程" :value="fieldValue" />
					<van-cell title="培训年度" :value="form.pxnd" />
					<van-cell title="所在地区" :value="form.szdq" />
					<van-cell title="审验地区" :value="form.sydq" />
					<div style="margin: 16px; text-align: center">
						<van-button round plain type="info" native-type="button" @click="onModify"> 修改 </van-button>&nbsp;
						<van-button round type="info" native-type="button" @click="onSubmit"> 确认提交 </van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- 报名成功 -->
		<div class="checked-area" v-if="isSignupSuccess">
			<van-icon name="checked" color="#67C23A" size="80"/>
			<div><span class="checked-msg">恭喜您，报名成功！</span></div>
			<div class="checked-plan">
				<van-cell-group title="报名信息" inset>
					<van-cell title="真实姓名" :value="form.studentName" />
					<van-cell title="身份证号" :value="form.certNum" />
					<van-cell title="性别" :value="form.xb" v-if="form.xb" />
					<van-cell title="手机号码" :value="form.dh" />
					<van-cell title="资格证类别" :value="form.cyzglb" v-if="form.cyzglb" />
					<van-cell title="资格证号" :value="form.cyzgzh" v-if="form.cyzgzh" />
					<van-cell title="准驾车型" :value="form.jszzjcx" />
					<van-cell title="报名课程" :value="fieldValue" />
					<van-cell title="培训年度" :value="form.pxnd" />
					<van-cell title="所在地区" :value="form.szdq" />
					<van-cell title="审验地区" :value="form.sydq" />
					<div style="margin: 16px">
						<van-button
							round
							block
							type="info"
							native-type="button"
							@click="gotoStudy"
						>点击进入在线教育平台，开始学习吧！
						</van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#4994df">{{ title || '提交中···' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {NavBar, Toast} from "vant";
	import {getStudentCerInfoWH, getStudyPlanInfoById, pushSignupInfoWH} from "../api/user";
	import { wipeBase64Rrefix } from "../utils";

    export default {
        name: "coach-certificate",
		components: {NavBar},
		data() {
			return {
				title: '',
				canBeSearch: true,
				showLoading: false,
				isSignupSuccess: false,
				isSignupConfirm: false,
				searchResult: false,
				showPicker: false,
				showPickerNd: false,
				showPickerDq: false,
				showPickerSy: false,
				form: {
					certNum: '',
					studentName: '',
					pxnd: '2022年度',
					pxnd_value: '2022',
					agentId: 0,
					studentPic: '',
				},
				columns: [],
				columnsNd: [
					{text: '2024年度', value: '2023'},
					{text: '2023年度', value: '2023'},
					{text: '2022年度', value: '2022'},
					{text: '2021年度', value: '2021'},
					{text: '2020年度', value: '2020'}
				],
				columnsDq: [
					{text: '乌海市', value: '150300'},
					{text: '阿拉善盟', value: '152900'}
				],
				columnsSy: [
					{text: '乌海市', value: '150300'},
					{text: '阿拉善盟', value: '152900'}
				],
				bookCount: 0,
				bookList: [],
				show: false,
				fieldValue: "",
				cascaderValue: "",
				categroy: "",
				pCategory: "",
				planName: "",
				options: [],
				options_wh: [
					{
						text: "继续教育培训",
						value: "115",
						children: [
							{ text: "客运", value: "29" },
							{ text: "货运", value: "28" },
							{ text: "出租车", value: "38" },
							{ text: "危险品", value: "24" },
							{ text: "出租车补学", value: "25" },
						],
					},
					{
						text: "计分教育培训",
						value: "136",
						children: [
							{ text: "客运", value: "134" },
							{ text: "货运", value: "136" },
							{ text: "危险品", value: "138" },
							{ text: "出租车", value: "18" },
						],
					},
				],
				options_als: [
					{
						text: "继续教育培训",
						value: "115",
						children: [
							// { text: "客运(上半年)", value: "41" },
							// { text: "客运(下半年)", value: "42" },
							// { text: "货运", value: "40" },
							// { text: "危险品(上半年)", value: "43" },
							// { text: "危险品(下半年)", value: "44" },
							// { text: "出租车", value: "45" },
							{ text: "客运", value: "133" },
							{ text: "货运（1年）", value: "40" },
							{ text: "货运（2年）", value: "135" },
							{ text: "危险品", value: "137" },
							{ text: "出租车（1年）", value: "45" },
							{ text: "出租车（2年）", value: "72" },
						],
					},
					{
						text: "计分教育培训",
						value: "136",
						children: [
							{ text: "客运", value: "49" },
							{ text: "货运", value: "48" },
							{ text: "危险品", value: "50" },
							{ text: "出租车", value: "47" },
						],
					},
				],
				photoList: [],
			}
		},
		mounted() {
			const {agent} = this.$route.query;
			if(typeof (agent) !== 'undefined') {
				this.form.agentId = agent;
			}
		},
		methods: {
			afterRead(file) {
				//this.photoList.push({'url': file.content, isImage: true});
				this.form.studentPic = wipeBase64Rrefix(file.content);
			},
        	reset() {
        		this.columns = [];
        		this.bookCount = 0;
        		this.bookList = [];
			},
			getStudentInfo() {
				this.reset();
				this.showLoading = true;
				getStudentCerInfoWH({
					"certNum": this.form.certNum,
					"studentName": this.form.studentName
				})
					.then((res) => {
						if (res) {
							if(res.length > 0) {
								let iCount = 0;
								for (let i = 0; i < res.length; i++) {
									if(res[i].fzjgdm === '150300') {
										this.columns.push({text: res[i].cyzglb, value: res[i].id_ECERT});
										this.bookList.push(res[i]);
										if(iCount === 0) {
											this.fillIn(res[i]);
											this.form.cyzglb = res[i].cyzglb;
											this.form.cyzglb_value = res[i].id_ECERT;
										}
										iCount++;
									}else if(res[i].fzjgdm === '152900'){
										this.columns.push({text: res[i].cyzglb, value: res[i].id_ECERT});
										this.bookList.push(res[i]);
										if(iCount === 0) {
											this.fillIn(res[i]);
											this.form.cyzglb = res[i].cyzglb;
											this.form.cyzglb_value = res[i].id_ECERT;
										}
										iCount++;
									}
								}
								this.bookCount = iCount;
								if(iCount > 0) {
									this.searchResult = true;
								} else {
									Toast.fail("没有查到乌海或阿拉善地区的资格证");
								}
							} else {
								// Toast.fail("没有查到资格证");
								this.$dialog.alert({
									message: `抱歉，您所选择的"${this.form.szdq}"没有查到相关报名信息，您可以手动报名。`,
									theme: 'round-button',
									showCancelButton: true,
									confirmButtonColor: '#fca142',
									width: 290,
								}).then(() => {
									this.canBeSearch = false;
									this.searchResult = true;
								})
								.catch(() => {});
							}
						}
						this.showLoading = false;
					})
					.catch(() => {
						this.showLoading = false;
					});
			},
			getPlanInfo(planId) {
				getStudyPlanInfoById(planId)
					.then((res) => {
						this.planName = res.planName;
					})
					.catch(() => {});
			},
			onConfirm() {
				if(typeof(this.form.studentName) === 'undefined' || this.form.studentName === '' || this.form.studentName == null) {
					return;
				}
				if(typeof(this.form.certNum) === 'undefined' || this.form.certNum === '' || this.form.certNum == null) {
					return;
				}
				if(!this.searchResult) {
					this.getStudentInfo();
				} else {
					if (!this.form.dh) {
						Toast.fail("请您填写报名手机号码");
						return;
					}
					if (this.fieldValue === "") {
						Toast.fail("请您选择报名课程");
						return;
					}
					if (!this.form.pxnd) {
						Toast.fail("请您选择培训年度");
						return;
					}
					if (!this.form.szdq) {
						Toast.fail("请您选择所在地区");
						return;
					}
					if (!this.form.sydq) {
						Toast.fail("请您选择从业资格证审验地区");
						return;
					}
					this.isSignupConfirm = true;

				}
			},
			onModify() {
				this.isSignupConfirm = false;
			},
			onSubmit() {
				this.showLoading = true;
				this.form.planId = this.categroy;
				this.form.jynd = this.form.pxnd_value;
				this.form.jynr = this.planName;
				if(!this.form.fzjgdm) this.form.fzjgdm = this.form.szdq_value;
				if(!this.form.xm) this.form.xm = this.form.studentName;
				if(!this.form.sfzh) this.form.sfzh = this.form.certNum;
				pushSignupInfoWH(this.form)
					.then(() => {
						this.isSignupSuccess = true;
						this.isSignupConfirm = false;
						this.showLoading = false;
					})
					.catch(() => {
						this.showLoading = false;
					});
			},
			onConfirmPicker(value) {
				for (let i = 0; i < this.bookList.length; i++) {
					if(this.bookList[i].id_ECERT === value.value) {
						this.fillIn(this.bookList[i]);
						this.form.cyzglb = value.text;
						this.form.cyzglb_value = value.value;
						break;
					}
				}
				this.showPicker = false;
			},
			onConfirmPickerNd(value) {
				this.form.pxnd = value.text;
				this.form.pxnd_value = value.value;
				this.showPickerNd = false;
			},
			onConfirmPickerDq(value) {
				this.form.szdq = value.text;
				this.form.szdq_value = value.value;
				this.form.sydq = value.text;
				this.form.sydq_value = value.value;
				if(value.value === '150300') {
					this.canBeSearch = true;
					this.searchResult = false;
					this.loadStudyPlan(value.value);
				} else if(value.value === '152900') {
					this.canBeSearch = true;
					this.loadStudyPlan(value.value);
					this.searchResult = false;
				}
				this.showPickerDq = false;
			},
			onConfirmPickerSy(value) {
				this.form.sydq = value.text;
				this.form.sydq_value = value.value;
				this.showPickerSy = false;
			},
			gotoStudy() {
				let categoryId = this.pCategory;
				if (categoryId === 115) {
					//继续教育
					window.location.href = "/index.html#/?appNo=APP02";
				} else if (categoryId === 136) {
					// 计时教育
					window.location.href = "/index.html#/?appNo=APP05";
				} else {
					// 默认
					window.location.href = "/index.html#/?appNo=APP02";
				}
			},
			fillIn(obj) {
				this.form.bcfzrq = obj.bcfzrq;
				this.form.ccfzrq = obj.ccfzrq;
				this.form.cljszrq = obj.cljszrq;
				this.form.csrq = obj.csrq;
				this.form.cyzgzh = obj.cyzgzh;
				this.form.dh = obj.dh;
				this.form.fzjgdm = obj.fzjgdm;
				this.form.fzjgmc = obj.fzjgmc;
				this.form.idArea = obj.id_AREA;
				this.form.idEcert = obj.id_ECERT;
				this.form.jszzjcx = obj.jszzjcx;
				this.form.scjzrq = obj.scjzrq;
				this.form.sfzh = obj.sfzh;
				this.form.whcd = obj.whcd;
				this.form.xb = obj.xb;
				this.form.xm = obj.xm;
				this.form.yxqjzrq = obj.yxqjzrq;
				this.form.zz = obj.zz;
				this.loadStudyPlan(obj.fzjgdm);

				for(let i=0; i<this.columnsDq.length; i++) {
					if(this.columnsDq[i].value === obj.fzjgdm) {
						this.form.szdq = this.columnsDq[i].text;
						this.form.szdq_value = this.columnsDq[i].value;
					}
				}

				for(let i=0; i<this.columnsSy.length; i++) {
					if(this.columnsSy[i].value === obj.fzjgdm) {
						this.form.sydq = this.columnsSy[i].text;
						this.form.sydq_value = this.columnsSy[i].value;
					}
				}
			},
			loadStudyPlan(cityCode) {
        		if(cityCode === '150300') {
					this.$set(this, 'options', this.options_wh);
				} else if(cityCode === '152900') {
					this.$set(this, 'options', this.options_als);
				}
			},
			onFinish({ selectedOptions }) {
				this.pCategory = selectedOptions[0].value;
				this.categroy = selectedOptions[selectedOptions.length - 1].value;
				this.show = false;
				this.fieldValue = selectedOptions.map((option) => option.text).join(" / ");
				this.getPlanInfo(this.categroy);
			},
		}
    }
</script>

<style scoped>
	#navbar {
		background-color: #409eff;
	}

	#navbar /deep/ .van-ellipsis {
		color: #fff !important;
	}

	.form-area {
		margin-top: 46px;
	}

	.title {
		margin: 0;
		padding: 16px 16px 16px;
		color: rgba(69, 90, 100, 0.6);
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
	}

	.checked-area {
		margin-top: 90px;
		text-align: center;
	}

	.confirm-area {
		text-align: center;
	}

	.checked-msg {
		font-size: 14px;
		color: #67c23a;
	}

	.checked-plan {
		text-align: left;
		margin-top: 40px;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.block {
		width: 120px;
		height: 120px;
		border-radius: 15px;
		background-color: #fff;
		padding-top: 32px;
	}
</style>
