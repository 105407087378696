<template>
	<div class="home_wrap" v-if="isShow">
		<van-icon v-if="show" @click="layout()" name="revoke" size="20px" color="white" style="position: absolute; z-index:2; top:1%;">理论培训</van-icon>
		<van-nav-bar
			:title="title"
			right-text="退出登录"
			@click-right="logout"
			:border="false"
			v-if="showLogout"
		></van-nav-bar>		
		<van-nav-bar
			:title="title"
			:border="false"
			v-if="!showLogout"
		></van-nav-bar>
		<div class="home-bg">		
			<van-tabs v-model="active" @click="onClickTab">
				<van-tab title="驾驶员培训">
					<div class="content">
						<p>选择车型类别</p>
						<vipTabCx :subject="subject" @click="selectSubject"/>
					</div>
				</van-tab>
				<van-tab title="资格证">
					<div class="content">
						<p>选择资格证类别</p>
						<vipTabZgz :subject="subject" @click="selectSubject"/>
					</div>
				</van-tab>
				<van-tab title="继续教育">
					<div class="content">
						<p>选择考题类别</p>
						<vipTabCy :subject="subject" @click="selectSubject"/>
					</div>
				</van-tab>
				<van-tab title="企业培训">
					<div class="content">
						<p>选择考题类别</p>
						<vipTabAqy :subject="subject" @click="selectSubject"/>
					</div>
				</van-tab>
			</van-tabs>
		</div>
<!--		<van-tabs v-model="active">-->
<!--			<van-tab title="驾驶员培训">-->
<!--				<vipTabCx :subject="subject" @click="selectSubject"/>-->
<!--			</van-tab>-->
<!--		</van-tabs>-->
<!--		<van-tabs v-model="active">-->
<!--			<van-tab title="资格证">-->
<!--				<vipTabZgz :subject="subject" @click="selectSubject"/>-->
<!--			</van-tab>-->
<!--		</van-tabs>-->
<!--		<van-tabs v-model="active">-->
<!--			<van-tab title="继续教育">-->
<!--				<vipTabCy :subject="subject" @click="selectSubject"/>-->
<!--			</van-tab>-->
<!--		</van-tabs>-->
<!--		<van-tabs v-model="active">-->
<!--			<van-tab title="企业培训">-->
<!--				<vipTabAqy :subject="subject" @click="selectSubject"/>-->
<!--			</van-tab>-->
<!--		</van-tabs>-->
	</div>
</template>

<script>
import vipTabCx from './components/vip-tab-cx.vue'
import vipTabZgz from './components/vip-tab-zgz.vue'
import vipTabCy from './components/vip-tab-cy.vue'
import vipTabAqy from './components/vip-tab-aqy.vue'
import { checkLogin } from '@/api/user'
import { getVipTabIndex, setVipTabIndex } from '@/utils/cookie';

export default {
	name: "viphome",
	data() {
		return {
			title: "题库类别",
			active: 0,
			subject: {
				categoryId: '',
			},
			showLogout: false,
			show:false,
			isShow:false,
		};
	},
	components: {
		vipTabCx,
		vipTabZgz,
		vipTabCy,
		vipTabAqy
	},
	computed: {
		token() {
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')).token
		},
		mobile() {
			return this.$store.state.user.mobile
		},
		appNo() {
			return sessionStorage.getItem('appno') || 'APP02'
		},
		toquestion() {
			return sessionStorage.getItem('toquestion')
		},
		organizationNo() {
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')) === null? '': JSON.parse(localStorage.getItem('loginInTKfor_APP02')).organizationNo;
		},
		categoryId() {
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')) === null? '': JSON.parse(localStorage.getItem('loginInTKfor_APP02')).categoryId
		},
	},
	created() {
		if (this.organizationNo === 'C24J6X01T0K') {
			this.isShow = false
			const appNo = 'APP02'
			const categoryId = this.categoryId
			console.log("viphome",categoryId)
			if(categoryId != null){
				this.$router.push({path: '/vipchoose', query: {appNo,categoryId}})
			}else{
				this.isShow = true
			}
		}else{
			this.isShow = true
		}
		if (this.toquestion != null) {
			this.show = true
		}
		// 登录校验身份
		// this.checkLogin()
		if (localStorage.getItem('loginInTKfor_APP02') != null) {
			this.showLogout = true;
		}
		if (getVipTabIndex()) {
			this.active = parseInt(getVipTabIndex());
		}
		
	},
	methods: {
		layout() {
			window.location.href = "/index.html#/login?appNo=" + this.toquestion;
		},
		// 登录校验身份
		checkLogin() {
			checkLogin({
				'token': this.token,
				'mobile': this.mobile
			}).then((data) => {
				console.log(data);
			}).catch(() => {
				this.$router.go(-1);
			})
		},
		// 选择类别
		selectSubject(val) {
			this.subject[val[0]] = val[1];
			//if (this.subject.categoryId !== '104' && this.subject.categoryId !== '105' && this.subject.categoryId !== '106') {
				if (this.subject.categoryId === '102' || this.subject.categoryId === '104' || this.subject.categoryId === '105' || this.subject.categoryId === '106') {
					this.$router.push({
						path: "/vipchoose",
						query: {categoryId: this.subject.categoryId, appNo: this.appNo}
					})
				} else {
					this.$router.push({
						path: "/vipchoose",
						query: {categoryId: this.subject.categoryId, subject: 0, appNo: this.appNo}
					})
				}
			// } else {
			// 	this.$dialog.alert({
			// 		message: "正在建设中,驾驶员培训目前仅支持小车",
			// 		theme: "round-button",
			// 		width: 290,
			// 	});
			// }
		},
		// 点击回退
		clickNavBack() {
			this.$router.go(-1);
		},
		// 退出登录
		logout() {
			// 清除缓存
			sessionStorage.clear();
			localStorage.clear();
			this.$router.push({path: '/viphome', query: {appNo: 'APP02'}})
		},
		onClickTab(index, title) {
			console.log(index, title);
			setVipTabIndex(index);
		},
	},
};
</script>

<style lang="less" scoped>
.home_wrap {
	min-height: 100vh;
	background: #f5f5f5;
}
.van-nav-bar {
	background-color: #fca142 !important;
}

::v-deep .van-icon {
	color: #fff;
}

::v-deep .van-ellipsis {
	color: #fff;
}

::v-deep .van-nav-bar__text {
	color: #fff;
}

.APP02 {
	@colorMain: #2db1ac;
	@colorSecond: #48d8a0;

	.van-nav-bar {
		background-color: @colorMain !important;
	}
}

.APP03 {
	@colorMain: #1175fe;
	@colorSecond: #3fadfa;

	.van-nav-bar {
		background-color: @colorMain !important;
	}
}

.home-bg {
	background: url('../../assets/vip-home/bg.png') no-repeat top center;
	padding: 15px;
	.content {
		padding: 20px 0;
		background: #fff;
		border-radius: 15px;
		box-shadow: 2px 2px 8px 1px rgb(46 176 172);
		p {
			font-size: 18px;
			text-align: center;
			padding: 0;
			margin: 0;
		}
	}
}

::v-deep .van-tabs__nav {
	background-color: transparent;
}
::v-deep .van-tabs__wrap {
	margin: 5px 0 20px;
}
::v-deep .van-tab--active {
	color: #fff;
}
::v-deep .van-tab {
	color: #fff;
}
::v-deep .van-tabs__line {
	background-color: #fff;
}
</style>
