<template>
	<div class="login_layout" v-if="isShow">
		<div class="main">
			<div class="main_portrait">
				<div class="box bg_primary">
					<img style="max-width:100%" src="https://h5.xunmeizaixian.com/picture/logo_jishi.gif" alt=""/>
				</div>
			</div>
			<div class="main_body">
				<h1 class="title">您好，请登录题库</h1>
				<div class="form">
					<van-field v-model="param.idCard" placeholder="请输入身份证号" maxlength="18">
						<div class="slot-icon" slot="left-icon"
							 style="width:40px; display: flex;align-items: center; justify-content: flex-end;">
							<img width="20px" height="20px" src="@/assets/icon-idcard.png" alt=""/>
						</div>
					</van-field>
					<van-field v-model="param.password" type="password" placeholder="默认密码为123456">
						<div class="slot-icon" slot="left-icon"
							 style="width:40px; display: flex;align-items: center; justify-content: flex-end;">
							<img width="20px" height="20px" src="@/assets/icon-password.png" alt=""/>
						</div>
					</van-field>
					<van-button class="btn" @click="clickLogin" :loading="loading">登录</van-button>
					<p class="explain">
						<span class="radio border" @click="isReadAgree = !isReadAgree">
							<transition name="fade">
								<span v-show="isReadAgree" class="bg_primary"></span>
							</transition>
						</span>
						<span class="text">我已阅读并同意<span class="text_primary"
														@click="$router.push('/service-agreement')">《服务协议》</span>及<span
							class="text_primary" @click="$router.push('/privacy-policy')">《隐私协议》</span></span>
					</p>
				</div>
			</div>
		</div>
		<div class="other_login">
			<div class="divider">
				<div class="line left"></div>
				<div class="text">其它登录方式</div>
				<div class="line right"></div>
			</div>
			<div class="login_option">
				<div class="phone_login" @click="$emit('toggleLogin', 'vipPhoneLogin')">
					<img src="@/assets/icon-phone-login.png" alt="">
					<p>手机号登录</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Validation from "@/utils/validation";
import {checkCertNum, checkLogin, wxAuth} from "@/api/user";

export default {
	name: 'vipIdCardLogin',
	data: () => ({
		loading: false,
		isReadAgree: false, // 是否同意协议
		isShow:false,
		param: {
			idCard: '',
			password: ''
		},
		rules: {
			idCard: [
				{required: true, message: '身份证号不能为空'},
				{count: 18, message: '身份证号码位数输入有误，请重新输入'}
			],
			password: [{required: true, message: '密码不能为空'}]
		},
	}),
	computed: {
		organizationNo() {
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')) === null? '': JSON.parse(localStorage.getItem('loginInTKfor_APP02')).organizationNo;
		},
	},
	created() {
		console.log("是否隐藏登录",this.organizationNo === 'C24J6X01T0K')
		if (this.organizationNo === 'C24J6X01T0K') {
			this.isShow = false
		}else{
			this.isShow = true
		}
	},
	mounted() {
		if (this.$route.query.state === 'single') {
			// 单点登录
			this.loading = true;
			const { idcard } = this.$route.query
			const { categoryId } = this.$route.query
			if (typeof (idcard) === 'undefined') {
				this.loading = false;
				return;
			}
			checkCertNum({"certNum": idcard, "password": '123456',"categoryId":categoryId}).then(() => {
				checkLogin({"certNum": idcard}).then(res => {
					const appNo = sessionStorage.getItem('appno') || 'APP02';
					localStorage.setItem('loginInTKfor_APP02', JSON.stringify({
						idcard: this.param.idCard,
						token: res.token,
						ifBind: res.ifBind,
						productId: res.productId,
						studentId: res.studentId,
						organizationNo: res.organizationNo,
						categoryId: categoryId
					}));
					
					if(categoryId != null){
						this.$router.push({path: '/vipchoose', query: {appNo,categoryId}})
					}else{
						this.$router.push({path: '/viphome', query: {appNo}})
					}
				}).catch(() => {
					this.loading = false;
				})
			}).catch(() => {
				this.loading = false;
			})
		}
	},
	methods: {
		// 是否同意协议和隐私策略
		loginVertify() {
			if (!this.isReadAgree) {
				this.$utils.dialog.alert('您需要同意服务协议及隐私策略才能操作。')
				return false
			}
			const message = new Validation(this.rules, this.param).start()
			if (message) {
				this.$utils.dialog.alert(message)
				return false
			}
			return true
		},
		// 登录
		clickLogin() {
			if (this.loginVertify()) {
				this.loading = true;
				checkCertNum({"certNum": this.param.idCard, "password": this.param.password}).then(() => {
					checkLogin({"certNum": this.param.idCard}).then(res => {
						const appNo = sessionStorage.getItem('appno') || 'APP02';
						localStorage.setItem('loginInTKfor_APP02', JSON.stringify({
							idcard: this.param.idCard,
							token: res.token,
							ifBind: res.ifBind,
							productId: res.productId,
							studentId: res.studentId,
							organizationNo: res.organizationNo
						}));
						if (this.$store.state.cache.screen.wx) {
							this.wxAuthPay()
						} else {
							this.$router.push({path: '/viphome', query: {appNo}})
						}
					}).catch(() => {
						this.loading = false;
					})
				}).catch(() => {
					this.loading = false;
				})
			}
		},
		// 微信授权
		wxAuthPay() {
			let href = window.location.href.replace('viplogin', 'viphome')
			wxAuth('', href).then(({url}) => {
				window.location.href = url
			})
		},
	}
}
</script>

<style scoped>
.main_portrait .box {
	overflow: hidden
}
</style>
