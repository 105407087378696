<template>
	<div class="login_layout" v-if="isShow">
		<div class="main">
			<div class="main_portrait">
				<div class="box bg_primary">
					<img style="max-width:100%;" src="https://h5.xunmeizaixian.com/picture/logo_jishi.gif" alt=""/>
				</div>
			</div>
			<div class="main_body">
				<h1 class="title">您好，请登录题库</h1>
				<div class="form">
					<van-field v-model="param.mobile" placeholder="请输入手机号码" maxlength="11">
						<div class="slot-icon" slot="left-icon"
							 style="width:40px; display: flex;align-items: center; justify-content: flex-end;">
							<img width="20px" height="20px" src="@/assets/icon-form-phone-app02.png" alt=""/>
						</div>
					</van-field>
					<van-field v-model="param.code" placeholder="请输入验证码" maxlength="6">
						<div class="slot-icon" slot="left-icon"
							 style="width:40px; display: flex;align-items: center; justify-content: flex-end;">
							<img width="20px" height="20px" src="@/assets/icon-form-code-app02.png" alt=""/>
						</div>
						<p slot="button" v-if="isCodeCount" class="text_primary"
						   style="text-decoration: underline; padding-right: 19px;font-size: 12px;">{{ codeCount }}S</p>
						<p slot="button" v-else class="text_primary" @click="clickGetCode"
						   style="text-decoration: underline; padding-right: 19px;font-size: 12px;">获取验证码</p>
					</van-field>
					<div @click="clickLogin">
						<van-button
							class="btn"
							native-type="submit"
							type="primary"
							block
							:loading="loading"
							loading-text="登录中..."
						>登录
						</van-button>
					</div>
					<p class="explain">
						<span class="radio border" style="border: 1px solid #2db1ac"
							  @click="isReadAgree = !isReadAgree">
							<transition name="fade">
								<span v-show="isReadAgree" class="bg_primary"></span>
							</transition>
						</span>
						<span class="text">我已阅读并同意<span class="text_primary"
														@click="$router.push('/service-agreement')">《服务协议》</span>及<span
							class="text_primary" @click="$router.push('/privacy-policy')">《隐私协议》</span></span>
					</p>
				</div>
			</div>
		</div>
		<div class="other_login">
			<div class="divider">
				<div class="line left"></div>
				<div class="text">其它登录方式</div>
				<div class="line right"></div>
			</div>
			<div class="login_option">
				<div class="phone_login" @click="$emit('toggleLogin', 'vipIdCardLogin')">
					<img src="@/assets/icon-idcard-login.png" alt="">
					<p>身份证号登录</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Validation from '@/utils/validation'
import {checkCode, checkLogin, getPhoneCode, wxAuth} from '../../../api/user'
import iconPhoneApp01 from '@/assets/icon-form-phone-app01.png'
import iconCodeApp01 from '@/assets/icon-form-code-app01.png'
import iconPhoneApp02 from '@/assets/icon-form-phone-app02.png'
import iconCodeApp02 from '@/assets/icon-form-code-app02.png'
import iconPhoneApp03 from '@/assets/icon-form-phone-app03.png'
import iconCodeApp03 from '@/assets/icon-form-code-app03.png'

export default {
	name: 'vipphoneLogin',
	data: () => ({
		imgs: {
			app01: [iconPhoneApp01, iconCodeApp01],
			app02: [iconPhoneApp02, iconCodeApp02],
			app03: [iconPhoneApp03, iconCodeApp03],
			app04: [iconPhoneApp01, iconCodeApp01],
			app05: [iconPhoneApp01, iconCodeApp01],
			app06: [iconPhoneApp01, iconCodeApp01],
		},
		appno: 'app02',
		isReadAgree: false, // 是否同意协议
		codeCount: 60,
		isCodeCount: false,
		codeTimer: null,
		isShow:false,
		param: {
			mobile: '',
			code: '',
		},
		rules: {
			mobile: [
				{required: true, message: '手机号不能为空'},
				{count: 11, message: '手机号码位数输入有误，请重新输入'}
			],
			code: [
				{required: true, message: '验证码不能为空'},
				{count: 6, message: '验证码输入有误，请重新输入'}
			]
		},
		loading: false,
	}),
	computed: {
		phoneIcon() {
			return this.imgs[this.appno][0]
		},
		codeIcon() {
			return this.imgs[this.appno][1]
		},
		organizationNo() {
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')) === null? '': JSON.parse(localStorage.getItem('loginInTKfor_APP02')).organizationNo;
		},
	},
	created() {
		console.log("是否隐藏登录",this.organizationNo === 'C24J6X01T0K')
		if (this.organizationNo === 'C24J6X01T0K') {
			this.isShow = false
		}else{
			this.isShow = true
		}
	},
	mounted() {
		const appno = sessionStorage.getItem('appno')
		this.appno = appno ? appno.toLowerCase() : 'APP02'
	},
	methods: {
		// 是否同意协议和隐私策略
		loginVertify() {
			if (!this.isReadAgree) {
				this.$utils.dialog.alert('您需要同意服务协议及隐私策略才能操作。')
				return false
			}
			const message = new Validation(this.rules, this.param).start()
			if (message) {
				this.$utils.dialog.alert(message)
				return false
			}
			return true
		},
		// 登录
		clickLogin() {
			let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0-9]|8[0-9]|9[1,8,9])\d{8}$/;
			if (this.loginVertify()) {
				if (!reg.test(this.param.mobile) || !this.param.mobile.length == 11) {
					return this.$utils.dialog.alert('手机号码格式不正确')
				}
				this.loading = true;
				checkCode({mobile: this.param.mobile, code: this.param.code}).then(res => {
					if (res.code == 0 || res.code == 200) {
						checkLogin({"mobile": this.param.mobile}).then(res => {
							const appNo = sessionStorage.getItem('appno') || 'APP02';
							localStorage.setItem('loginInTKfor_APP02', JSON.stringify({
								phone: this.param.mobile,
								token: res.token,
								ifBind: res.ifBind,
								productId: res.productId,
								studentId: res.studentId,
								organizationNo: res.organizationNo
							}));
							if (this.$store.state.cache.screen.wx) {
								this.wxAuthPay()
							} else {
								this.$router.push({path: '/viphome', query: {appNo}})
							}
						}).catch(() => {
							this.loading = false;
						})
					}
				}).catch(() => {
					this.loading = false;
				})
			}
		},
		// 微信授权
		wxAuthPay() {
			let href = window.location.href.replace('viplogin', 'viphome')
			wxAuth('', href).then(({url}) => {
				window.location.href = url
			})
		},
		// 开始倒计时
		beginCodeComputed() {
			const _this = this
			this.codeCount = 60
			this.isCodeCount = true
			const beginTimetamp = new Date().getTime()
			this.codeTimer = setInterval(() => {
				const curTimetamp = new Date().getTime()
				const curTimeVal = Math.floor((curTimetamp - beginTimetamp) / 1000)
				if (curTimeVal >= 60) {
					clearInterval(_this.codeTimer)
					_this.isCodeCount = false
				} else {
					_this.codeCount = 60 - curTimeVal
				}
			}, 1000)
		},
		// 点击获取验证码
		clickGetCode() {
			const _this = this
			const message = new Validation({mobile: this.rules.mobile}, {mobile: this.param.mobile}).start()
			if (message) {
				this.$utils.dialog.alert(message)
			} else {
				getPhoneCode({mobile: this.param.mobile}).then((res) => {
					console.log(res);
					_this.beginCodeComputed()
					if (res.data.smsCode) {
						_this.param.code = res.data.smsCode
					}
					// _this.$utils.dialog.alert(res.msg)
				})
			}
		},
	}
}
</script>

<style scoped>
.main_portrait .box {
	overflow: hidden
}
</style>
